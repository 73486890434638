<template>
  <v-card tile elevation="0" class="pt-10">
    <v-card-title>
      <v-btn outlined text @click="goBack" class="pl-2 pr-3"
        ><v-icon dense>mdi-arrow-left</v-icon
        >{{ this.$t("user.headerRow.back") }}</v-btn
      >
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        label="Search"
        append-icon="mdi-magnify"
      ></v-text-field>
      <v-btn outlined class="mx-3" color="blue" @click="refresh()"
        ><v-icon>mdi-refresh</v-icon>{{ $t("user.headerRow.refresh") }}</v-btn
      >
      <v-btn outlined color="green" @click="openDetail(-1)">
        <v-icon>mdi-plus</v-icon> Add user
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :key="this.$store.state.usersKey"
        :items="users"
        :sort-by="['lastName']"
        :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, -1] }"
        :search="search"
        dense
      >
        <template v-slot:item.id="{ item }">
          <v-btn icon @click="openDetail(item.id)" text>
            <v-icon color="grey"> mdi-pencil </v-icon>
          </v-btn>
          <!-- <v-btn icon @click="showDeleteUserDialog(item.id)" text>
            <v-icon color="grey"> mdi-delete </v-icon>
          </v-btn> -->
        </template>
        <template v-slot:item.isActive="{ item }">
          <v-icon v-if="item.isActive" color="green"> mdi-check </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      users: [],
      search: "",
      headers: [
        {
          text: "",
          value: "action",
          width: 30,
          class: "wfh-blue white--text",
        },
        {
          text: this.$t("user.headerRow.firstName"),
          value: "firstName",
          class: "wfh-blue white--text",
        },
        {
          text: this.$t("user.headerRow.lastName"),
          value: "lastName",
          class: "wfh-blue white--text",
        },
        {
          text: this.$t("user.headerRow.email"),
          value: "email",
          class: "wfh-blue white--text",
        },
        {
          text: this.$t("user.headerRow.role"),
          value: "roleName",
          width: 80,
          class: "wfh-blue white--text",
        },
        {
          text: this.$t("user.headerRow.htc"),
          value: "htcName",
          class: "wfh-blue white--text",
        },
        {
          text: this.$t("user.headerRow.isActive"),
          value: "isActive",
          width: 100,
          class: "wfh-blue white--text",
        },
        {
          text: "Action",
          value: "id",
          class: "wfh-blue white--text",
          align: "right",
        },
      ],
      loading: false,
    };
  },
  methods: {
    openDetail(id, user) {
      this.$store.commit("showModal", {
        componentName: "UserDetail",
        parameter: { id, user },
      });
    },
    goBack() {
      this.$router.push({ name: "Dashboard" });
      this.$store.dispatch("loadDashboardData");
    },
    refresh() {
      this.getUsers();
    },
    getUsers() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_URI}/user`)
        .then((response) => {
          this.users = response.data;
        })
        .catch((e) => {});
    },
  },
  computed: {
    refreshFlag() {
      return this.$store.state.usersKey;
    },
  },
  watch: {
    refreshFlag() {
      this.refresh();
    },
  },
  created() {
    this.getUsers();
  },
};
</script>
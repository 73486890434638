import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        modals: [],
        filters: {
            region: null, // ["Eastern Mediterranean", "Europe", "Americas", "Western Pacific", "South East Asia", "Africa"],
            gni: null, // ["Low","Middle Low","Middle High","High"],
            dateOfRegistration: null, //[2018, 2021],
            hemophiliaType: null, //["Type A","Type B","Unknown"],
            ageGroup: null, // [0, 100]
            htcId: null, // 1
            htcName: null,
            severity: null
        },
        currentUser: {
            jwt: null,
            id: null,
            firstName: null,
            lastName: null,
            role: null,
            email: null
        },
        graphQLLoadingCount: 0,
        dashboardData: null,
        isDashboardDataLoading: false,
        dashbordDataLoadingText: "",
        usersKey: 0,
        compareData: {
            A: {},
            B: {}
        },
        compareDataKey: 0
    },
    mutations: {
        showModal(state, {
            componentName,
            parameter,
            width,
            maxwidth
        }) {
            state.modals.push({
                component: componentName,
                parameter: parameter,
                width: width,
                maxwidth: maxwidth
            });
        },
        hideModal(state) {
            state.modals.splice(state.modals.length - 1);
        },
        setFilters(state, {
            gni,
            dateOfRegistration,
            region,
            hemophiliaType,
            ageGroup,
            htcId,
            htcName,
            severity
        }) {
            state.filters = Object.assign({}, state.filters, {
                gni: gni, // == null ? state.filters.gni : gni,
                dateOfRegistration: dateOfRegistration, // == null ? state.filters.dateOfRegistration : dateOfRegistration,
                region: region, // == null ? state.filters.region : region,
                hemophiliaType: hemophiliaType, // == null ? state.filters.hemophiliaType : hemophiliaType,
                ageGroup: ageGroup, // == null ? state.filters.ageGroup : ageGroup
                htcId: htcId,
                htcName: htcName,
                severity: severity
            });
        },
        setJwt(state, {
            jwt
        }) {
            state.currentUser.jwt = jwt
            sessionStorage.setItem('token', jwt)
        },
        setUser(state, {
            id,
            firstName,
            lastName,
            role,
            email,
            htc
        }) {
            state.currentUser.id = id
            state.currentUser.firstName = firstName
            state.currentUser.lastName = lastName
            state.currentUser.role = role
            state.currentUser.email = email
            state.currentUser.htc = htc
            state.compareDataKey = state.compareDataKey + 1;
            console.log(state.compareDataKey);
        },
        setLoadingCount(state, loadingCountModifier) {
            state.graphQLLoadingCount += loadingCountModifier
        },
        logout(state) {
            state.currentUser = {
                id: null,
                firstName: null,
                jwt: null,
                email: null,
                lastName: null,
                role: null,
                htc: null
            }
            sessionStorage.removeItem('token')
            state.compareDataKey = state.compareDataKey + 1;
            console.log(state.compareDataKey);
        },
        setDashboardData(state, dashboardData) {
            state.dashboardData = dashboardData;
        },

        setCompareData(state, {
            compareData,
            setData
        }) {
            state.compareData[setData] = compareData;
        },

        toggleIsDashboardLoadingCustomText(state, {
            toggle,
            text
        }) {
            state.isDashboardDataLoading = toggle;
            state.dashbordDataLoadingText = text;
        },
        toggleIsDashboardLoading(state, toggle) {
            state.isDashboardDataLoading = toggle;
            if (toggle == true)
                state.dashbordDataLoadingText = "Generating the dashboard, Please wait ..."; //$t("app.loading");
        },
        setUsersKey(state, value) {
            state.usersKey = state.usersKey + 1;
        },
        // setCompareDataKey(state) {
        //     state.compareDataKey = state.compareDataKey + 1;
        // },
    },
    actions: {
        async loadDashboardData({
            commit,
            dispatch
        }) {
            commit("toggleIsDashboardLoading", true);
            var filters = this.getters.filters;
            var data = {
                regions: filters.region,
                gni: filters.gni,
                ageGroup: filters.ageGroup,
                registrationYear: filters.dateOfRegistration,
                hemophiliaTypes: filters.hemophiliaType,
                htcId: parseInt(filters.htcId),
                severity: filters.severity
            };
            var xhr = axios.post(`${process.env.VUE_APP_BACKEND_URI}/stats`, {
                regions: filters.region,
                gni: filters.gni,
                ageGroup: filters.ageGroup,
                registrationYear: filters.dateOfRegistration,
                hemophiliaTypes: filters.hemophiliaType,
                htcId: parseInt(filters.htcId),
                severity: filters.severity
            });
            xhr.then((res) => {
                commit('setDashboardData', res.data)
            }).finally(() => {
                commit('toggleIsDashboardLoading', false);
            });

            return xhr;
        },




        async loadCompareData({
            commit,
            dispatch
        }, {
            Region,
            GNI,
            Severity,
            Year,
            HtcId,
            dataSet
        }) {

            commit("toggleIsDashboardLoading", true);
            var filters = this.getters.filters;
            var data = {
                Region,
                GNI, //: filters.gni,
                Severity, //: filters.severity,
                Year, //: filters.dateOfRegistration
                HtcId, // ageGroup: filters.ageGroup,
                // hemophiliaTypes: filters.hemophiliaType,
            };
            var xhr = axios.post(`${process.env.VUE_APP_BACKEND_URI}/Stats/Compare`,
                data
            );

            xhr.then((res) => {
                commit('setCompareData', {
                    compareData: res.data,
                    setData: dataSet
                })
            }).finally(() => {
                commit('toggleIsDashboardLoading', false);
            });


            return xhr;

        },







        async saveUser({
            commit,
            dispatch
        }, {
            id,
            firstName,
            lastName,
            email,
            userRoleId,
            isActive,
            htcId
        }) {
            if (id != null && id > 0) {
                return dispatch('updateUser', {
                    id,
                    firstName,
                    lastName,
                    email,
                    userRoleId,
                    isActive,
                    htcId
                })
            } else {
                return dispatch('addUser', {
                    firstName,
                    lastName,
                    email,
                    userRoleId,
                    isActive,
                    htcId
                })
            }
        },
        async updateUser({
            commit
        }, {
            id,
            firstName,
            lastName,
            email,
            userRoleId,
            isActive,
            htcId
        }) {
            var input = {
                id,
                firstName,
                lastName,
                email,
                userRoleId,
                isActive,
                htcId
            }
            var request = axios.patch(`${process.env.VUE_APP_BACKEND_URI}/user`, input)

            return request;
        },
        async addUser({
            commit
        }, {
            firstName,
            lastName,
            email,
            userRoleId,
            htcId
        }) {
            var input = {
                firstName,
                lastName,
                email,
                userRoleId,
                htcId
            }
            var request = axios.post(`${process.env.VUE_APP_BACKEND_URI}/user`, input)

            return request;
        },
    },
    getters: {
        isUserAdmin: (state) => {
            var role = state.currentUser.role
            if (role == null || role.trim().length == 0) {
                return false
            }
            return role.toLowerCase() == 'admin';
        },
        isUserWFH: (state) => {
            var role = state.currentUser.role
            if (role == null || role.trim().length == 0) {
                return false
            }
            return role.toLowerCase() == 'admin' || role.toLowerCase() == 'wfh';
        },

        rawFilters: (state) => {
            var filters = {
                ...state.filters
            }
            for (let k of Object.keys(filters)) {
                if (filters[k] == null)
                    delete filters[k]
            }
            return filters
        },
        filters: (state) => {
            var filters = {
                ...state.filters
            }

            filters.ageGroup = filters.ageGroup == null ? {
                min: null,
                max: null
            } : filters.ageGroup
            filters.dateOfRegistration = filters.dateOfRegistration == null ? {
                min: null,
                max: null
            } : filters.dateOfRegistration
            return filters;
        },
        hasFilters: (state) => {
            const filters = state.filters
            const keys = Object.keys(filters)

            for (let k of keys) {
                if (filters[k] != null) {
                    return true
                }
            }
            return false
        },
        isDashboardDataLoading(state) {
            return state.isDashboardDataLoading;
        },
        dashbordDataLoadingText: (state) => {
            return state.dashbordDataLoadingText;
        }
    }
});
<template>
  <v-app>
    <v-app-bar
      app
      fluid
      clipped-left
      v-resize="onResize"
      :height="bannerHeight"
      src="/assets/img/banner_3.png"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          aspect-ratio="15"
          contain
          min-width="100%"
          min-height="100%"
        ></v-img>
      </template>
      <v-app-bar-title> </v-app-bar-title>

      <v-spacer></v-spacer>
      <span v-if="isUserLoggedIn" class="white--text"
        >Welcome {{ userFullName }}</span
      >
      <v-menu offset-y v-if="isUserLoggedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-if="isUserLoggedIn" v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list class="pt-0 pb-2">
          <v-card width="200" class="elevation-0 mx-3">
            <div class="subtitle-2 text-center">
              <v-card-text class="py-2">
                <v-icon x-large :color="this.$store.state.loadingBarColor"
                  >mdi-account-circle-outline</v-icon
                >
                <br />
                {{ userFullName }}
              </v-card-text>
            </div>
            <v-btn
              v-if="isAdmin || isWFH"
              text
              block
              outlined
              color="blue"
              @click="changePage('Users')"
              >{{ $t("app.nav.manageUsers") }}</v-btn
            >
            <v-divider v-if="isAdmin || isWFH" class="my-2"></v-divider>
            <v-btn block outlined class="red--text" @click="logout()">{{
              $t("app.logout")
            }}</v-btn>
          </v-card>
        </v-list>
      </v-menu>
      <v-btn icon @click="openLoginDialog()" v-if="!isUserLoggedIn">
        <v-icon>mdi-account-outline</v-icon>
      </v-btn>
    </v-app-bar>

    <router-view name="filters" class="mdburger"></router-view>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
        <dialog-handler />
        <app-loading-dialog></app-loading-dialog>
      </v-container>
    </v-main>
    <app-footer></app-footer>
    <snackbar></snackbar>
  </v-app>
</template>

<script>
import DialogHandler from "./components/DialogHandler";
import AppFooter from "./components/Footer";
import Snackbar from "./components/Snackbar";
import AppLoadingDialog from "./components/dialogs/Loader";
export default {
  components: {
    dialogHandler: DialogHandler,
    appFooter: AppFooter,
    snackbar: Snackbar,
    appLoadingDialog: AppLoadingDialog,
  },
  data() {
    return {
      drawer: true,
      extendAppBar: false,
      bannerHeight: 127,
    };
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.state.currentUser.jwt;
    },
    isAdmin() {
      return this.$store.state.currentUser.role == "Admin";
    },
    isWFH() {
      return this.$store.state.currentUser.role == "WFH";
    },
    isHTC() {
      return this.$store.state.currentUser.role == "HTC";
    },
    currentRouteName() {
      return this.$route.name;
    },
    filters() {
      return this.$store.state.filters;
    },
    hasFilters() {
      const keys = Object.keys(this.filters);
      for (let k of keys) {
        if (this.filters[k] != null && k != "htcName") {
          return true;
        }
      }
      return false;
    },
    userFullName() {
      var firstName = this.$store.state.currentUser.firstName;
      var lastName = this.$store.state.currentUser.lastName;

      return firstName + " " + lastName;
    },
    // showExtendedAppBar() {
    //   return this.currentRouteName == "Dashboard" && this.hasFilters;
    // },
  },
  methods: {
    openLoginDialog() {
      this.$store.commit("showModal", {
        componentName: "LoginDialog",
      });
    },
    changePage(pageName) {
      this.$router.push({ name: pageName }).catch(() => {});
    },
    logout() {
      this.$store.commit("logout");
      this.$store.commit("setFilters", {});
      this.$router.push({ name: "Dashboard" }).catch((e) => {});
      //this.$store.dispatch("loadDashboardData");
    },

    onResize() {
      var height = window.innerWidth / 15;
      if (height > 80) height = 80;
      this.bannerHeight = height;
    },
  },
};
</script>

<style scoped>
</style>
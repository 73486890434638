<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="filter-header">{{
      this.$t("filters.region.title")
    }}</v-expansion-panel-header>
    <v-expansion-panel-content class="pt-0">
      <v-checkbox
        color="#0082ed"
        :label="$t('filters.region.all')"
        v-model="region"
        @change="regionFilter('all')"
        value="all"
        hide-details
      ></v-checkbox>
      <v-checkbox
        class="my-0 py-0"
        v-for="regionInstance in regionList"
        :key="regionInstance.value"
        color="#0082ed"
        :label="regionInstance.label"
        v-model="region"
        @change="regionFilter($event)"
        :value="regionInstance.value"
        hide-details
      ></v-checkbox>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
export default {
  data() {
    return {
      updateTimeout: null,
      region: ["all"],
      regionList: [
        { label: this.$t("filters.region.africa"), value: "Africa" },
        { label: this.$t("filters.region.americas"), value: "Americas" },
        {
          label: this.$t("filters.region.easternMediterranean"),
          value: "Eastern Mediterranean",
        },
        { label: this.$t("filters.region.europe"), value: "Europe" },
        {
          label: this.$t("filters.region.southEastAsia"),
          value: "South East Asia",
        },
        {
          label: this.$t("filters.region.westernPacific"),
          value: "Western Pacific",
        },
      ],
    };
  },
  computed: {
    filters() {
      return this.$store.state.filters;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler(o) {
        if (o["region"] != null) {
          this.region = o.region;
        } else {
          this.region = ["all"];
        }
      },
    },
  },
  methods: {
    applyFilters(regions) {
      if (this.updateTimeout) clearTimeout(this.updateTimeout);
      this.updateTimeout = setTimeout(() => {
        var filters = this.$store.state.filters;
        filters.region = regions;
        this.$store.commit("setFilters", filters);
      }, 500);
    },
    regionFilter(val) {
      if (val == "all" || this.region.length == 0 || this.region.length == 6) {
        this.region = ["all"];
        this.applyFilters(null);
      } else if (this.region.includes("all")) {
        this.region = this.region.filter((x) => x != "all");
        this.applyFilters(this.region);
      } else {
        this.applyFilters(this.region);
      }
    },
  },
};
</script>
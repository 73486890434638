import AgeDistribution from "./AgeDistributionWidget";
import AnnualBleedRateWidget from './AnnualBleedRateWidget';
import AnnualJointBleedRateWidget from './AnnualJointBleedRateWidget';
import AverageAgeFirstBleed from "./AverageAgeFirstBleedWidget";
import AverageAgeFirstJointBleed from "./AverageAgeFirstJointBleedWidget";
import AverageBleedsPerPatientWidget from './AverageBleedPerPatientWidget';
import BleedEventsByLocationWidget from './BleedEventsByLocationWidget';
import CountryCount from "./CountryCountWidget";
import GNIMedianDiagnosisAgeWidget from "./GNIMedianDiagnosisAgeWidget";
import HCVStatusWidget from './HCVStatusWidget';
import HemophiliaTypeRatioWidget from "./HemophiliaTypeRatioWidget";
import HIVStatusWidget from './HIVStatusWidget';
import HTCCount from "./HTCCountWidget.vue";
import HTCMedianAgeAtDiagnosisWidget from "./HTCMedianAgeAtDiagnosisWidget";
import InhibitorsCountWidget from "./InhibitorsCountWidget";
import InhibitorsLastestPositiveTestPatientCountWidget from "./InhibitorsLastestPositiveTestPatientCountWidget";
import InhibitorsPositivePercentageWidget from "./InhibitorsPositivePercentageWidget";
import InhibitorsUniquePatientCountWidget from "./InhibitorsUniquePatientCountWidget";
import MainCountersWidget from './MainCountersWidget';
import MedianAgeFirstBleed from "./MedianAgeFirstBleedWidget";
import MedianAgeFirstJointBleed from "./MedianAgeFirstJointBleedWidget";
import MedianDaysPerHospitalizationWidget from "./MedianDaysPerHospitalizationWidget";
import NumberofHospitalizationByReasonWidget from "./NumberOfHospitalizationByReasonWidget";
import NumberOfHospitalizationWidget from "./NumberOfHospitalizationWidget";
import NumberOfPatientsWithNoBleedWidget from './NumberOfPatientsWithNoBleedWidget';
import NumberOfPatientsWithTargetJointWidget from './NumberOfPatientsWithTargetJointsWidget';
import PatientCountByGNI from "./PatientCountByGNI";
import PatientCountByRegion from "./PatientCountByRegion";
import PatientCounterWidget from "./PatientCounterWidget";
import PatientEmployementWidget from "./PatientEmployementWidget";
import PatientsByDNAVariantTypeWidget from "./PatientsByDNAVariantTypeWidget";
import PatientsByDNAVariantWidget from "./PatientsByDNAVariantWidget";
import PatientsByCountryWidget from "./PatientsByCountryWidget";
import PatientScholarityWidget from "./PatientScholarityWidget";
import PatientsWithProphylaxisRatioWidget from './PatientsWithProphylaxisRatioWidget';
import PatientWidget from "./PatientRatioWidget.vue";
import PercentageOfPatientsHospitalizedWidget from "./PercentageOfPatientsHospitalizedWidget";
import PositiveInhibitorsCountWidget from "./PositiveInhibitorsCountWidget";
import ProphylaxisRatioByGNI from "./ProphylaxisRatioByGNI";
import ProphylaxisRatioByRegion from "./ProphylaxisRatioByRegion";
import RegionMedianDiagnosisAgeWidget from "./RegionMedianDiagnosisAgeWidget";
import RegisteredPatientsOverTime from "./RegisteredPatientsOverTimeWidget";
import PercentageCurrentlyProphylaxisWidget from './PercentageCurrentlyProphylaxisWidget';
import TreatmentCountWidget from './TreatmentCountWidget';


export default {
    "patient-widget": PatientWidget,
    "htc-count": HTCCount,
    "country-count": CountryCount,
    "average-age-first-bleed": AverageAgeFirstBleed,
    "average-age-first-joint-bleed": AverageAgeFirstJointBleed,
    "median-age-first-bleed": MedianAgeFirstBleed,
    "median-age-first-joint-bleed": MedianAgeFirstJointBleed,
    "registered-patients-over-time": RegisteredPatientsOverTime,
    "patient-employement-widget": PatientEmployementWidget,
    "patient-scholarity-widget": PatientScholarityWidget,
    "patients-by-country-widget": PatientsByCountryWidget,
    "age-distribution-widget": AgeDistribution,
    "inhibitors-count-widget": InhibitorsCountWidget,
    "inhibitors-unique-patient-count-widget": InhibitorsUniquePatientCountWidget,
    "positive-inhibitors-count-widget": PositiveInhibitorsCountWidget,
    "inhibitors-positive-percentage-widget": InhibitorsPositivePercentageWidget,
    "inhibitors-lastest-positive-test-patient-count-widget": InhibitorsLastestPositiveTestPatientCountWidget,
    //"htc-median-diagnosis-age-widget": HTCMedianDiagnosisAgeWidget,
    "patients-by-dna-variant": PatientsByDNAVariantWidget,
    "patients-by-dna-variant-type": PatientsByDNAVariantTypeWidget,
    "patient-counter-widget": PatientCounterWidget,    
    "region-median-diagnosis-age-widget": RegionMedianDiagnosisAgeWidget,
    "gni-median-diagnosis-age-widget": GNIMedianDiagnosisAgeWidget,
    "patients-with-prophylaxis-ratio-widget": PatientsWithProphylaxisRatioWidget,
    "main-counters-widget": MainCountersWidget,
    "treatment-count-widget": TreatmentCountWidget,
    "percentage-currently-prophylaxis-widget": PercentageCurrentlyProphylaxisWidget,
    "hiv-status-widget": HIVStatusWidget,
    "hcv-status-widget": HCVStatusWidget,
    "average-bleeds-per-patient-widget": AverageBleedsPerPatientWidget,
    "number-of-patients-with-no-bleed-widget": NumberOfPatientsWithNoBleedWidget,
    "number-of-patients-with-target-joints-widget": NumberOfPatientsWithTargetJointWidget,
    "bleed-events-by-location-widget": BleedEventsByLocationWidget,
    "annual-bleed-rate-widget": AnnualBleedRateWidget,
    "annual-joint-bleed-rate-widget": AnnualJointBleedRateWidget,
    "median-days-per-hospitalization-widget": MedianDaysPerHospitalizationWidget,
    "number-of-hospitalization-by-reason-widget": NumberofHospitalizationByReasonWidget,
    "number-of-hospitalization-widget": NumberOfHospitalizationWidget,
    "percentage-of-patients-hospitalized-widget": PercentageOfPatientsHospitalizedWidget,
    "htc-median-age-at-diagnosis-widget": HTCMedianAgeAtDiagnosisWidget,
    "hemophilia-type-ratio-widget": HemophiliaTypeRatioWidget,
    // "median-age-first-bleed-combined" : MedianAgeFirstBleedCombined_MD
    "patient-count-by-gni": PatientCountByGNI,
    "patient-count-by-region": PatientCountByRegion,
    "prophylaxis-ratio-by-region": ProphylaxisRatioByRegion,
    "prophylaxis-ratio-by-gni": ProphylaxisRatioByGNI
}
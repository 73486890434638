<template>
  <div class="md_center">
    <img src="/assets/img/Treatment.jpg" />
    <div class="md_flex_col pl-2 ml-2">
      <div class="mb-2 pt-2">
        <span class="md_spacer"></span>
        <span
          >{{ $t("widgets.percentageCurrentlyProphylaxisWidget.title") }}:
        </span>
      </div>
      <div>
        <span
          v-counter="prophylaxisRatio"
          style="font-weight: 900; font-size: 1em"
          >0</span
        ><span style="font-weight: 600; font-size: 1em">%</span>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import BaseWidget from "./BaseWidget";
export default {
  components: {},
  data() {
    return {
      treatmentStats: "0",
      loading: true,
    };
  },
  computed: {
    prophylaxisRatio() {
      if (this.$store.state.dashboardData == null) return 0;

      var data = this.$store.state.dashboardData["prophylaxisRatio"];
      return Math.round(data.patientWithProphylaxisPercentage);
    },
    filters() {
      return this.$store.getters.filters;
    },
    filtersRegion() {
      return this.filters.region;
    },
    filtersAge() {
      return this.filters.ageGroup;
    },
    filtersGni() {
      return this.filters.gni;
    },
    filtersRegistrationYears() {
      return this.filters.dateOfRegistration;
    },
  },
  watch: {
    prophylaxisRatio(val) {
      if (val != null) this.loading = false;
    },
  },
  created() {
    this.loading = true;
  },
  updated() {
    this.loading = false;
  },
};
</script>

<style scoped>
.md_center img {
  max-height: 60px;
}
.md_center h3 {
  color: #666;
}
.md_center {
  display: flex;
  align-items: center;
}

.md_flex_col {
  display: flex;
  flex-direction: column;
  padding-left: 2em;
}
</style>
<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      clipped
      app
      permanent
      mobile-breakpoint="31337"
    >
      <v-divider></v-divider>

      <v-container fluid>
        <v-app-bar-title class="my-2 ml-3 mdtitle"
          >{{ this.$t("filters.title") }}
        </v-app-bar-title>

        <v-expansion-panels accordion class="mb-3">
          <date-of-registration-filter></date-of-registration-filter>

          <hemophilia-type-filter></hemophilia-type-filter>
          <severity-filter></severity-filter>
          <age-group-filter></age-group-filter>

          <region-filter></region-filter>

          <gross-nation-income-filter></gross-nation-income-filter>

          <htc-filter v-if="isUserLoggedIn"></htc-filter>
        </v-expansion-panels>

        <app-bar-filters-list v-if="hasFilters"></app-bar-filters-list>

        <v-btn
          class="md_transparent__btn"
          v-if="hasFilters"
          block
          text
          tile
          @click="clearFilters()"
          >{{ $t("filters.clearAll") }}</v-btn
        >
        <!-- <br><a class="md_white__btn" href="/Compare">Comparison view &raquo;</a> -->
      </v-container>
    </v-navigation-drawer>
  </div>
</template>
<script>
import DateOfRegistrationFilter from "./filters/DateOfRegistrationFilter.vue";
import RegionFilter from "./filters/RegionFilter.vue";
import HemophiliaTypeFilter from "./filters/HemophiliaTypeFilter.vue";
import GrossNationIncomeFilter from "./filters/GrossNationalIncomeFilter.vue";
import AgeGroupFilter from "./filters/AgeGroupFilter.vue";
import AppBarFiltersList from "./filters/AppBarFiltersList.vue";
import HTCFilter from "./filters/HTCFilter.vue";
import SeverityFilter from "./filters/SeverityFilter.vue";
export default {
  components: {
    dateOfRegistrationFilter: DateOfRegistrationFilter,
    regionFilter: RegionFilter,
    hemophiliaTypeFilter: HemophiliaTypeFilter,
    grossNationIncomeFilter: GrossNationIncomeFilter,
    ageGroupFilter: AgeGroupFilter,
    appBarFiltersList: AppBarFiltersList,
    htcFilter: HTCFilter,
    severityFilter: SeverityFilter,
  },
  data() {
    return {
      form: null,
      filtersTimeout: null,
      drawer: false,
    };
  },
  computed: {
    hasFilters() {
      return this.$store.getters.hasFilters;
    },
    filters() {
      return this.$store.state.filters;
    },
    isAdmin() {
      return this.$store.getters.isUserAdmin;
    },
    isUserLoggedIn() {
      return this.$store.state.currentUser.jwt;
    },
  },
  watch: {
    filters() {
      if (this.filtersTimeout) clearTimeout(this.filtersTimeout);

      this.filtersTimeout = setTimeout((_) => {
        this.$store.dispatch("loadDashboardData");
      }, 1000);
    },
  },
  methods: {
    clearFilters() {
      this.$store.commit("setFilters", {});
    },
    changeDashboardWindows() {
      this.$store.commit("setIsDashboard");
      this.$store.dispatch("loadDashboardData");
    },
  },
  created() {},
};
</script>


<style scoped>
.md_transparent__btn {
  background-color: transparent;
  color: white;
  padding: 8px 25px;
  border-radius: 2px;
  text-decoration: none;
}
.md_white__btn {
  background-color: white !important;
  color: #123175;
  padding: 8px 25px;
  border-radius: 2px;
  text-decoration: none;
}
</style>
<template>
    <div class="md_center">
      <img src="/assets/img/Target-joint_1.jpg" />
      <div class="md_flex_col pl-2 ml-2">
        <div class="mb-2 pt-2"><span class="md_spacer"></span>
          <span>{{ $t("widgets.numberOfPatientsWithTargetJointsWidget.title") }}: </span>
        </div>
        <div>
          <span v-counter="bleedCount"  style="font-weight: 900; font-size: 1em;">0</span><span  style="font-weight: 600; font-size: 1em;"></span>
        </div>      
      </div>
    </div>
  <!-- <base-widget
    :titleUnder="true"
    :md="12"
    :sm="4"
    imageName="/assets/img/Target-joint_1.jpg"
    :imageWidth="100"
  >
    <template slot="title">{{
      $t("widgets.numberOfPatientsWithTargetJointsWidget.title")
    }}</template>
    <template slot="text">
      <h1 v-counter="bleedCount" class="text-center">0</h1>
    </template></base-widget
  > -->
</template>
<script>
import gql from "graphql-tag";
import BaseWidget from "./BaseWidget";
export default {
  components: { 
    //baseWidget: BaseWidget 
  },
  data() {
    return {
      bleedCountStats: 0,
      loading: true,
    };
  },
  computed: {
    bleedCount() {
      if (this.$store.state.dashboardData == null) return 0;
      return this.$store.state.dashboardData[
        "numberOfPatientsWithTargetJoints"
      ];
    },
    filters() {
      return this.$store.getters.filters;
    },
    filtersRegion() {
      return this.filters.region;
    },
    filtersAge() {
      return this.filters.ageGroup;
    },
    filtersGni() {
      return this.filters.gni;
    },
    filtersRegistrationYears() {
      return this.filters.dateOfRegistration;
    },
  },
  watch: {
    bleedCount(val) {
      if (val != null) this.loading = false;
    },
  },
  // apollo: {
  //   bleedCountStats: {
  //     query: gql`
  //       query BleedCountStats(
  //         $regions: [String]
  //         $minAge: Int
  //         $maxAge: Int
  //         $gni: [String]
  //         $hemoTypes: [String]
  //         $minRegistrationYear: Int
  //         $maxRegistrationYear: Int
  //       ) {
  //         numberOfPatientsWithTargetJoints(
  //           regions: $regions
  //           ageGroup: { min: $minAge, max: $maxAge }
  //           gni: $gni
  //           hemophiliaTypes: $hemoTypes
  //           registrationYear: {
  //             min: $minRegistrationYear
  //             max: $maxRegistrationYear
  //           }
  //         )
  //       }
  //     `,
  //     variables() {
  //       var filters = this.filters;
  //       var v = {
  //         regions: filters.region,
  //         minAge: this.filtersAge.min,
  //         maxAge: this.filtersAge.max,
  //         age: this.filtersAge,
  //         gni: filters.gni,
  //         hemoTypes: filters.hemophiliaType,
  //         minRegistrationYear: this.filtersRegistrationYears.min,
  //         maxRegistrationYear: this.filtersRegistrationYears.max,
  //       };
  //       return v;
  //     },
  //     update: function (data) {
  //       return data.numberOfPatientsWithTargetJoints;
  //     },
  //     watchLoading({ loading, etc }) {
  //       this.loading = loading;
  //     },
  //   },
  // },
  created() {
    this.loading = true;
  },
  updated() {
    this.loading = false;
  },
};
</script>
<style scoped>
.md_center img {
  max-height: 70px;
}
.md_center h3 {
  color: #666;
}
.md_center {
  display: flex;
  //justify-content: center;
  align-items: center;
}

.md_flex_col {
  display: flex;
  flex-direction: column;
  padding-left: 2em;
}
</style>
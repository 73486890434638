<template>
    <!-- <base-widget
    :titleUnder="true"
    :md="12"
    :sm="4"
    imageName="/assets/img/Blood-drop.jpg"
    :imageWidth="40"
  >
    <template slot="title">{{
      $t("widgets.htcMedianDiagnosisAge.title")
    }}</template>
    <template slot="text">
      <h2 class="text-center">
        <i18n-n v-counter="htcMedianAgeData" :value="htcMedianAgeData"
          >0.0</i18n-n
        >
        {{ $t("widgets.htcMedianDiagnosisAge.yearsOld") }}
      </h2>
    </template>
  </base-widget>
   -->
  
  <!-- NEW LAYOUT 25 OCTOBER 2021 -Mario -->
  <div class="md_center">
    <img src="/assets/img/Blood-drop.jpg" />
    <div class="flex-col">
      <p>{{ $t("widgets.HtcMedianAgeAtDiagnosis.title") }}</p>
      <h3>{{ medianDaysPerHospitalizationData }}</h3>
    </div>
  </div>
</template>


<script>
// import BaseWidget from "./BaseWidget";
export default {
  components: {
    // baseWidget: BaseWidget,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    filters() {
      return this.$store.getters.filters;
    },
    filtersRegion() {
      return this.filters.region;
    },
    filtersAge() {
      return this.filters.ageGroup;
    },
    filtersGni() {
      return this.filters.gni;
    },
    filtersRegistrationYears() {
      return this.filters.dateOfRegistration;
    },
    htcMedianAgeData() {
      if (this.$store.state.dashboardData == null) return 0;
      var data = this.$store.state.dashboardData["medianAgeAtDiagnosisForHTC"];
      if (data != null) {
        return Math.round(data.result);
      }
      return 0;
    },
  },
  watch: {
    htcMedianAgeData(val) {
      if (val != null) this.loading = false;
    },
  },
  created() {
    this.loading = true;
  },
  updated() {
    this.loading = false;
  },
};
</script>






<style scoped>
.md_center img {
  max-height: 100px;
}
.md_center h3 {
  color: #666;
}
.md_center {
  display: flex;
  //justify-content: center;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
  padding-left: 2em;
}
</style>
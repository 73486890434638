<template>
  <section>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="People with hemophilia distribution by age"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="800px"
      @hasDownloaded="hasDownloaded($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <!-- PDF Content Here -->
        <base-widget :loading="loading" type="graph" :md="12" :sm="4">
          <template slot="title">{{
            $t("widgets.patientsAgeDistribution.title")
          }}</template>
          <template slot="text">
            <v-switch v-model="chartSwitch" color="#5b1b6e"></v-switch>
            <!-- <div class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-circular>
      </div> -->
            <div class="md_legend">
              <v-slide-y-transition>
                <GChart
                  class="pa-1"
                  v-if="chartSwitch && !loading"
                  :setting="{
                    packages: ['corechart', 'bar'],
                  }"
                  type="PieChart"
                  :data="chartData"
                  :options="{
                    pieHole: 0.4,
                    animation: {
                      duration: 1500,
                      easing: 'out',
                      startup: true,
                    },
                    legend: {
                      position: 'right',
                      maxLines: '11',
                    },
                    chartArea: {
                      width: '100%',
                      height: '90%',
                    },
                    width: 'auto',
                    height: '300',
                    colors: [
                      '#8c1f70',
                      '#009fdb',
                      '#5b1b6e',
                      '#b91b66',
                      '#123175',
                    ],
                    sliceVisibilityThreshold: 0,
                  }"
                ></GChart>
              </v-slide-y-transition>
            </div>
            <v-slide-y-transition>
              <GChart
                v-if="!chartSwitch && !loading"
                :setting="{ packages: ['corechart'] }"
                type="ColumnChart"
                :data="chartData"
                :options="{
                  animation: {
                    duration: 1500,
                    easing: 'out',
                    startup: true,
                  },
                  legend: {
                    position: 'none',
                  },
                  hAxis: {
                    title: patientsAge,
                    titleTextStyle: {
                      bold: true,
                      italic: false,
                    },
                    gridlines: {
                      count: 10,
                    },
                  },
                  vAxis: {
                    title: patientsCount,
                    titleTextStyle: {
                      bold: true,
                      italic: false,
                    },
                    gridlines: {
                      count: 10,
                    },
                    viewWindow: {
                      min: 0,
                      max: highestValue,
                    },
                    viewWindowMode: 'explicit',
                  },
                  annotations: {
                    alwaysOutside: true,
                    textStyle: {
                      opacity: 1,
                    },
                    stem: {
                      length: 0,
                    },
                  },
                  width: 'auto',
                  height: '500',
                  colors: ['#0067ab'],
                }"
              ></GChart>
            </v-slide-y-transition>
          </template>
        </base-widget>
      </section>
    </vue-html2pdf>

    <base-widget :loading="loading" type="graph" :md="12" :sm="4">
      <template slot="title">{{
        $t("widgets.patientsAgeDistribution.title")
      }}</template>
      <template slot="text">
        <v-switch v-model="chartSwitch" color="#5b1b6e"></v-switch>
        <div class="md_legend">
          <v-slide-y-transition>
            <GChart
              class="pa-1"
              v-if="chartSwitch && !loading"
              :setting="{ packages: ['corechart', 'bar'] }"
              type="PieChart"
              :data="chartData"
              :options="{
                pieHole: 0.4,
                animation: {
                  duration: 1500,
                  easing: 'out',
                  startup: true,
                },
                legend: {
                  position: 'right',
                  maxLines: '11',
                },
                chartArea: {
                  width: '100%',
                  height: '90%',
                },
                width: 'auto',
                height: '300',
                colors: ['#8c1f70', '#009fdb', '#5b1b6e', '#b91b66', '#123175'],
                sliceVisibilityThreshold: 0,
              }"
            ></GChart>
          </v-slide-y-transition>
        </div>
        <v-slide-y-transition>
          <GChart
            v-if="!chartSwitch && !loading"
            :setting="{ packages: ['corechart'] }"
            type="ColumnChart"
            :data="chartData"
            :options="{
              animation: {
                duration: 1500,
                easing: 'out',
                startup: true,
              },
              legend: {
                position: 'none',
              },
              hAxis: {
                title: patientsAge,
                titleTextStyle: {
                  bold: true,
                  italic: false,
                },
                gridlines: {
                  count: 10,
                },
              },
              vAxis: {
                title: patientsCount,
                titleTextStyle: {
                  bold: true,
                  italic: false,
                },
                gridlines: {
                  count: 10,
                },
                viewWindow: {
                  min: 0,
                  max: highestValue,
                },
                viewWindowMode: 'explicit',
              },
              annotations: {
                alwaysOutside: true,
                textStyle: {
                  opacity: 1,
                },
                stem: {
                  length: 0,
                },
              },
              width: 'auto',
              height: '500',
              colors: ['#0067ab'],
            }"
          ></GChart>
        </v-slide-y-transition>
      </template>
    </base-widget>
    <div class="dl">
      <input type="image" src="assets/img/ikon.png" @click="generateReport" />
    </div>
  </section>
</template>
<script>
import BaseWidget from "./BaseWidget";
import gql from "graphql-tag";
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: { baseWidget: BaseWidget, VueHtml2pdf },
  data() {
    return {
      chartSwitch: true,
      loading: true,
      patientStats: [],
    };
  },
  methods: {
    generateReport() {
      this.startPagination();
      this.$refs.html2Pdf.generatePdf();
    },
    startPagination() {
      this.$store.commit("toggleIsDashboardLoadingCustomText", {
        toggle: true,
        text: this.$t("app.generatingPdf"),
      });
    },
    hasDownloaded(event) {
      this.$store.commit("toggleIsDashboardLoading", false);
    },
  },
  computed: {
    highestValue() {
      let v = [];
      for (const x in this.$store.state.dashboardData.patientsByAge) {
        v.push(
          this.$store.state.dashboardData.patientsByAge[x].patientCount
        );
      }
      return Math.max(...v) + 300;
    },
    patientsAgeDistribution() {
      if (this.patientStats.length) {
        return this.patientStats;
      }
      return [];
    },
    patientsAge() {
      return this.$t("widgets.patientsAgeDistribution.age");
    },
    patientsCount() {
      return this.$t("widgets.patientsAgeDistribution.count");
    },
    filters() {
      return this.$store.getters.filters;
    },
    filtersRegion() {
      return this.filters.region;
    },
    filtersAge() {
      return this.filters.ageGroup;
    },
    filtersGni() {
      return this.filters.gni;
    },
    filtersHemoTypes() {
      return this.filters.hemophiliaType;
    },
    filtersRegistrationYears() {
      return this.filters.dateOfRegistration;
    },
    chartData() {
      if (this.$store.state.dashboardData == null) return null;
      var data = this.$store.state.dashboardData["patientsByAge"];

      var chart = [
        [
          this.$t("widgets.patientsAgeDistribution.age"),
          this.$t("widgets.patientsAgeDistribution.count"),
          { role: "annotation" },
        ],
      ];

      if (data.length == 0) {
        chart.push(["", 0, "", ""]);
        return chart;
      }

      data.forEach((x) => {
        var ageGroup = "";

        switch (x.age) {
          case 0:
            ageGroup = this.$t("filters.ageGroup.0");
            break;
          case 4:
            ageGroup = this.$t("filters.ageGroup.1");
            break;
          case 17:
            ageGroup = this.$t("filters.ageGroup.2");
            break;
          case 44:
            ageGroup = this.$t("filters.ageGroup.3");
            break;
          case 200:
            ageGroup = this.$t("filters.ageGroup.4");
            break;
        }
        chart.push([ageGroup, x.patientCount, x.patientCount + ""]);
      });
      return chart;
    },
  },
  watch: {
    chartData(val) {
      if (val != null) this.loading = false;
    },
  },
  created() {
    this.loading = true;
  },
  updated() {
    this.loading = false;
  },
};
</script>

<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="filter-header">{{
      this.$t("filters.grossNationalIncome.title")
    }}</v-expansion-panel-header>
    <v-expansion-panel-content class="pt-0">
      <v-checkbox
        color="#0082ed"
        :label="$t('filters.grossNationalIncome.all')"
        v-model="grossNationalIncome"
        @change="grossNationIncomeFilter('all')"
        value="all"
        hide-details
      ></v-checkbox>
      <v-checkbox
        class="my-0 py-0"
        v-for="gni in grossNationalIncomeList"
        :key="gni.value"
        color="#0082ed"
        :label="gni.label"
        v-model="grossNationalIncome"
        @change="grossNationIncomeFilter($event)"
        :value="gni.value"
        hide-details
      ></v-checkbox>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
export default {
  data() {
    return {
      udpateTimeout: null,
      grossNationalIncome: ["all"],
      grossNationalIncomeList: [
        { label: this.$t("filters.grossNationalIncome.high"), value: "H" },
        {
          label: this.$t("filters.grossNationalIncome.middleHigh"),
          value: "UM",
        },
        {
          label: this.$t("filters.grossNationalIncome.middleLow"),
          value: "LM",
        },
        { label: this.$t("filters.grossNationalIncome.low"), value: "L" },
      ],
    };
  },
  computed: {
    filter() {
      return this.$store.state.filters.gni;
    },
  },
  watch: {
    filter(o) {
      if (o != null) {
        this.grossNationalIncome = o;
      } else this.grossNationalIncome = ["all"];
    },
  },
  methods: {
    applyFilter(gni) {
      if (this.updateTimeout) clearTimeout(this.updateTimeout);
      this.updateTimeout = setTimeout(() => {
        var filters = this.$store.state.filters;
        filters.gni = gni;
        this.$store.commit("setFilters", filters);
      }, 500);
    },
    grossNationIncomeFilter(val) {
      if (
        val == "all" ||
        this.grossNationalIncome.length == 0 ||
        this.grossNationalIncome.length == 4
      ) {
        this.grossNationalIncome = ["all"];
        this.applyFilter(null);
      } else {
        this.grossNationalIncome = this.grossNationalIncome.filter(
          (x) => x != "all"
        );
        this.applyFilter(this.grossNationalIncome);
      }
    },
  },
};
</script>
import Vue from 'vue'
import VueGoogleCharts from 'vue-google-charts'
import axios from "axios";
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import i18n from './i18n';
import momentTimezone from 'moment-timezone'
import store from './store'
import './assets/css/global.css'
import jwtDecode from 'jwt-decode';
import counter from './directives/incrementalCounter'


// Warn if overriding existing method
if (Array.prototype.equals)
    console.warn("Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code.");
// attach the .equals method to Array's prototype to call it on any array
Array.prototype.equals = function (array) {
    // if the other array is a falsy value, return
    if (!array)
        return false;

    // compare lengths - can save a lot of time 
    if (this.length != array.length)
        return false;

    for (var i = 0, l = this.length; i < l; i++) {
        // Check if we have nested arrays
        if (this[i] instanceof Array && array[i] instanceof Array) {
            // recurse into the nested arrays
            if (!this[i].equals(array[i]))
                return false;
        } else if (this[i] != array[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;
        }
    }
    return true;
}
// Hide method from for-in loops
Object.defineProperty(Array.prototype, "equals", {
    enumerable: false
});


Vue.config.productionTip = false
Vue.use(require('vue-moment'), {
    momentTimezone
});
Vue.use(VueGoogleCharts);

export default axios.create({
    timeout: 1800000
});

axios.interceptors.request.use(
    function (config) {
        const token = sessionStorage.getItem("token");
        if (token != null) {
            config.headers.Authorization = "bearer " + sessionStorage.getItem("token");
        }

        return config;
    },
    function (err) {
        return Promise.reject(err);
    }
);

// const onInit = (store) => {
//     store.dispatch('init')
// }

//onInit(store);

router.beforeEach(async (to, from, next) => {
    // if token exists AND is valid
    var token = sessionStorage.getItem('token');
    console.log(to);
    if ((token && jwtDecode(token).exp * 1000 > Date.now())) {
        // does not have the user in store (on refresh)
        var xhr = axios.get(`${process.env.VUE_APP_BACKEND_URI}/auth/silentauth`)
        await xhr.then((response) => {
            store.commit("setUser", {
                id: response.data.data.id,
                firstName: response.data.data.firstName,
                lastName: response.data.data.lastName,
                role: response.data.data.role,
                email: response.data.data.email,
                htc: response.data.data.htc
            });
            store.commit("setJwt", {
                jwt: token
            });

        }).catch(e => {
            console.log(e);
        })
        if (to.matched.some(x => x.meta.requiresAuth)) {
            if (to.matched.some(x => x.meta.role == 'WFH')) {
                if (store.getters.isUserWFH) {
                    next();
                } else {
                    next({
                        name: 'Dashboard'
                    })
                }
            }
        }
        next()
    } else {
        if (to.name == 'Dashboard') {
            next()
        }
        else if (to.name == 'resetpassword') {
            next()
        } else {
            next({
                name: 'Dashboard'
            })
        }
    }
});


new Vue({
    vuetify,
    store,
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')

Vue.component('LoginDialog', () => import('./components/dialogs/LoginDialog.vue'));
Vue.component('UserDetail', () => import('./components/dialogs/UserDetail.vue'));
Vue.component('ForgotPasswordDialog', () => import('./components/dialogs/ForgotPasswordDialog.vue'));
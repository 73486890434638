var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-widget',{attrs:{"loading":_vm.loading,"type":"graph","md":12,"sm":4}},[_c('template',{slot:"title"},[_vm._v(_vm._s(_vm.$t("widgets.hivStatusWidget.title")))]),_c('template',{slot:"text"},[_c('v-switch',{attrs:{"color":"accent"},model:{value:(_vm.chartSwitch),callback:function ($$v) {_vm.chartSwitch=$$v},expression:"chartSwitch"}}),_c('v-slide-y-transition',[(_vm.chartSwitch && !_vm.loading)?_c('GChart',{attrs:{"setting":{ packages: ['corechart', 'bar'] },"type":"PieChart","data":_vm.comorbidityData,"options":{
          animation: {
            duration: 1500,
            easing: 'out',
            startup: true,
          },
          legend: {
            position: 'right',
            maxLines: '11',
          },
          width: 'auto',
          height: '500',
          colors: ['#e31837', '#616265', '#9d9fa2'],
        }}}):_vm._e()],1),_c('v-slide-y-transition',[(!_vm.chartSwitch && !_vm.loading)?_c('GChart',{attrs:{"setting":{ packages: ['corechart'] },"type":"ColumnChart","data":_vm.comorbidityData,"options":{
          animation: {
            duration: 1500,
            easing: 'out',
            startup: true,
          },
          legend: {
            position: 'none',
          },
          width: 'auto',
          height: '500',
          colors: ['#e31837', '#616265', '#9d9fa2'],
          vAxis: {
            title: _vm.numberOfPatients,
            titleTextStyle: {
              bold: true,
              italic: false,
            },
          },
          hAxis: {
            title: _vm.hivStatus,
            titleTextStyle: {
              bold: true,
              italic: false,
            },
          },
        }}}):_vm._e()],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-tabs background-color="#123175" dark v-model="tab" class="">
      <v-tab key="Dashboard">Dashboard</v-tab>
      <v-tab key="CompareData">Compare Data</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-container fluid>
          <v-row class="kpi">
            <v-col xl="2" md="4" sm="6" cols="12" class="widget">
              <patient-counter-widget></patient-counter-widget>
            </v-col>
            <v-col
              xl="2"
              md="4"
              sm="6"
              cols="12"
              class="widget"
              v-if="!isUserLoggedIn"
            >
              <country-count></country-count>
            </v-col>
            <v-col
              xl="2"
              md="4"
              sm="6"
              cols="12"
              class="widget"
              v-if="!isUserLoggedIn"
            >
              <htc-count></htc-count>
            </v-col>
            <v-col
              xl="2"
              md="4"
              sm="6"
              cols="12"
              class="widget"
              v-if="isUserLoggedIn && isHTCSelected"
            >
              <number-of-hospitalization-widget></number-of-hospitalization-widget>
            </v-col>
            <v-col xl="2" md="4" sm="6" cols="12" class="widget">
              <patient-widget></patient-widget>
            </v-col>
            <v-col
              xl="2"
              md="4"
              sm="6"
              cols="12"
              class="widget"
              v-if="isUserLoggedIn && isHTCSelected"
            >
              <percentage-of-patients-hospitalized-widget></percentage-of-patients-hospitalized-widget>
            </v-col>
            <v-col
              xl="2"
              md="4"
              sm="6"
              cols="12"
              class="widget"
              v-if="isUserLoggedIn && isHTCSelected"
            >
              <median-days-per-hospitalization-widget></median-days-per-hospitalization-widget>
            </v-col>
            <v-col xl="2" md="4" sm="6" cols="12" class="widget">
              <hemophilia-type-ratio-widget></hemophilia-type-ratio-widget>
            </v-col>
            <v-col xl="2" md="4" sm="6" cols="12" class="widget">
              <div class="double_box_1">
                <h3 class="pb-2">Median age at first bleed</h3>
                <median-age-first-bleed class="pb-2"></median-age-first-bleed>
                <median-age-first-joint-bleed></median-age-first-joint-bleed>
              </div>
            </v-col>
            <v-col xl="2" md="4" sm="6" cols="12" class="widget">
              <div class="double_box_2">
                <h3 class="pb-2">Annual bleed rate (at baseline)</h3>
                <annual-bleed-rate-widget
                  class="pb-2"
                ></annual-bleed-rate-widget>
                <annual-joint-bleed-rate-widget></annual-joint-bleed-rate-widget>
              </div>
            </v-col>
            <v-col xl="2" md="4" sm="6" cols="12" class="widget">
              <!-- <htc-median-age-at-diagnosis-widget
          v-if="isUserLoggedIn && isHTCSelected"          
        ></htc-median-age-at-diagnosis-widget> -->
              <number-of-patients-with-target-joints-widget></number-of-patients-with-target-joints-widget>
            </v-col>
            <v-col xl="2" md="4" sm="6" cols="12" class="widget">
              <percentage-currently-prophylaxis-widget></percentage-currently-prophylaxis-widget>
            </v-col>
            <v-col xl="2" md="4" sm="6" cols="12" class="widget">
              <inhibitors-lastest-positive-test-patient-count-widget></inhibitors-lastest-positive-test-patient-count-widget>
            </v-col>
            <!-- <average-bleeds-per-patient-widget></average-bleeds-per-patient-widget> -->
          </v-row>
          <v-row class="kpi">
            <v-col cols="12" class="widget" v-if="!isUserLoggedIn">
              <patients-by-country-widget></patients-by-country-widget>
            </v-col>
            <v-col cols="12" md="6" xl="4" class="widget" v-if="!isUserHtc">
              <patient-count-by-gni></patient-count-by-gni>
            </v-col>
            <v-col cols="12" md="6" xl="4" class="widget" v-if="!isUserHtc">
              <patient-count-by-region></patient-count-by-region>
            </v-col>
            <v-col cols="12" md="6" xl="4" class="widget" v-if="!isUserHtc">
              <gni-median-diagnosis-age-widget></gni-median-diagnosis-age-widget>
            </v-col>
            <v-col
              cols="12"
              md="6"
              xl="4"
              class="widget"
              v-if="!isUserLoggedIn"
            >
              <region-median-diagnosis-age-widget></region-median-diagnosis-age-widget>
            </v-col>
            <v-col cols="12" md="6" xl="4" class="widget" v-if="!isUserHtc">
              <prophylaxis-ratio-by-gni></prophylaxis-ratio-by-gni>
            </v-col>
            <v-col cols="12" md="6" xl="4" class="widget" v-if="!isUserHtc">
              <prophylaxis-ratio-by-region></prophylaxis-ratio-by-region>
            </v-col>
            <v-col cols="12" md="6" xl="4" class="widget">
              <patient-employement-widget></patient-employement-widget>
            </v-col>
            <v-col
              cols="12"
              md="6"
              xl="4"
              class="widget"
              v-if="isUserLoggedIn && isHTCSelected"
            >
              <number-of-hospitalization-by-reason-widget></number-of-hospitalization-by-reason-widget>
            </v-col>
            <v-col cols="12" md="6" xl="4" class="widget" v-if="isUserLoggedIn">
              <registered-patients-over-time></registered-patients-over-time>
            </v-col>
            <v-col cols="12" md="6" xl="4" class="widget">
              <age-distribution-widget></age-distribution-widget>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-row class="pt-6">
          <v-col class="pt-0 offset-xl-2" cols="6" xl="4"
            ><compare-view title="Region A" abbr="(A)" :key="this.$store.state.compareDataKey"></compare-view
          ></v-col>
          <v-col class="pt-0" cols="6" xl="4"
            ><compare-view title="Region B" abbr="(B)" :key="this.$store.state.compareDataKey"></compare-view
          ></v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import widgets from "./widgets/Widgets";
import compareView from "./CompareView.vue";

export default {
  components: {
    ...widgets,
    compareView,
  },
  data() {
    return {
      tab: null,
      filteredWidgets: [
        {
          component: "main-counters-widget",
          show: true,
        },
      ],
      startTime: 0,
      loadingTimer: null,
      counterCardSize: {
        md: 4,
        sm: 6,
      },
      graphWidgets: [],
      counterWidgets: [],
      privateGraphWidgets: [],
    };
  },
  computed: {
    loadingCount() {
      return this.$store.state.graphQLLoadingCount;
    },
    isUserLoggedIn() {
      return this.$store.state.currentUser.jwt;
    },
    isUserHtc() {
      return this.$store.state.currentUser.role == "HTC";
    },

    isHTCSelected() {
      return this.$store.state.filters.htcId;
    },
  },
  created() {
    this.startTime = Date.now();
    this.$store.dispatch("loadDashboardData");
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

.kpi .widget {
  padding: 10px 10px 0 0;
}

.kpi .widget > div,
.kpi .widget > section {
  padding: 10px;
  background-color: #fff;
  border: 1px solid #b3b3b3;
  box-shadow: 5px 5px 20px #ccc;
  border-radius: 4px;
  height: 100%;
}

.kpi .widget > section {
  position: relative;
}

.theme--light.v-navigation-drawer {
  background-color: #123175;
  color: white;
}

.v-card__text {
  font-weight: 400;
  font-size: 1em;
  color: #666;
}

.v-expansion-panel-header {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1em;
  background-color: #009fdb;
  color: white;
  border: 1px solid #123175;
  height: 80px;
}

.v-expansion-panel-header--active {
  background-color: #0067ab;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #123175;
}

.v-expansion-panel-content__wrap {
  background-color: white;
  padding: 20px 10px;
}

.v-dialog .v-card__title {
  background-color: white !important;
  color: #0067ab;
  font-size: 2em;
}

/* Filter remove button */
.v-chip__content {
  font-size: 0.8em;
}

/* banner */
.v-toolbar__image {
  background-color: #123175;
}

.v-btn--icon.v-size--default .v-icon,
.v-btn--fab.v-size--default .v-icon {
  color: white;
}

/* Bouger le banner à gauche */
.v-image__image,
.v-image__image--contain {
  background-position: center left !important;
}

/* Changements du 27 Juillet */

.v-expansion-panel-content.py-0 .v-expansion-panel-content__wrap {
  padding-top: 0;
  padding-bottom: 0;
}

.v-expansion-panel-header.filter-header,
.v-expansion-panel-header.v-expansion-panel-header--active.filter-header {
  min-height: 40px;
  height: 40px;
}

.filter-header {
  padding: 0px 20px 0px 20px;
  font-size: 12px;
}

/* Start of 1440px */
@media screen and (min-width: 1440px) {
  .compare-data {
    grid-column: 1/4;
    margin-top: 20px;
  }
}
/* End of 1440px breakpoint */

h3 {
  color: #666;
}

.dl {
  position: absolute;
  right: 1em;
  bottom: 1em;
}

.dl input {
  height: 32px;
  width: 32px;
  transition-duration: 0.3s;
}

.dl input:hover {
  height: 35px;
  width: 36px;
}
</style>

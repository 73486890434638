<template>
  <section>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="Median age at diagnosis by GNI"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="800px"
      @hasDownloaded="hasDownloaded($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <!-- PDF Content Here -->
        <base-widget :loading="loading" type="graph" :md="12" :sm="4">
          <template slot="title">{{
            this.$t("widgets.gniMedianDiagnosisAge.title")
          }}</template>
          <template slot="text">
            <v-slide-y-transition>
              <GChart
                v-if="!loading"
                :setting="{ packages: ['corechart'] }"
                type="ColumnChart"
                :data="chartData"
                :options="{
                  animation: {
                    duration: 1500,
                    easing: 'out',
                    startup: true,
                  },
                  legend: {
                    position: 'none',
                  },
                  vAxis: {
                    title: medianAge,
                    titleTextStyle: {
                      bold: true,
                      italic: false,
                    },
                    gridlines: {
                      count: 10,
                    },
                    viewWindow: {
                      min: 0,
                      max: maxValue + 25,
                    },
                    viewWindowMode: 'explicit',
                  },
                  hAxis: {
                    title: gni,
                    titleTextStyle: {
                      bold: true,
                      italic: false,
                    },
                  },
                  annotations: {
                    alwaysOutside: true,
                    textStyle: {
                      opacity: 1,
                    },
                    stem: {
                      length: 0,
                    },
                  },
                  width: 'auto',
                  height: '500',
                }"
              ></GChart>
            </v-slide-y-transition>
          </template>
        </base-widget>
      </section>
    </vue-html2pdf>

    <base-widget :loading="loading" type="graph" :md="12" :sm="4">
      <template slot="title">{{
        this.$t("widgets.gniMedianDiagnosisAge.title")
      }}</template>
      <template slot="text">
        <GChart
          v-if="!loading"
          :setting="{ packages: ['corechart'] }"
          type="ColumnChart"
          :data="chartData"
          :options="{
            animation: {
              duration: 1500,
              easing: 'out',
              startup: true,
            },
            legend: {
              position: 'none',
            },
            vAxis: {
              title: medianAge,
              titleTextStyle: {
                bold: true,
                italic: false,
              },
              gridlines: {
                count: 10,
              },
              viewWindow: {
                min: 0,
                max: maxValue + 25,
              },
              viewWindowMode: 'explicit',
            },
            hAxis: {
              title: gni,
              titleTextStyle: {
                bold: true,
                italic: false,
              },
            },
            annotations: {
              alwaysOutside: true,
              textStyle: {
                opacity: 1,
              },
              stem: {
                length: 0,
              },
            },
            width: 'auto',
            height: '500',
          }"
        ></GChart>
      </template>
    </base-widget>

    <div class="dl">
      <input type="image" src="assets/img/ikon.png" @click="generateReport" />
    </div>
  </section>
</template>
<script>
import BaseWidget from "./BaseWidget.vue";
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: {
    baseWidget: BaseWidget,
    VueHtml2pdf,
  },
  data() {
    return {
      loading: true,
      patientStats: [],
      maxValue: 0,
    };
  },

  methods: {
    generateReport() {
      this.startPagination();
      this.$refs.html2Pdf.generatePdf();
    },
    startPagination() {
      this.$store.commit("toggleIsDashboardLoadingCustomText", {
        toggle: true,
        text: this.$t("app.generatingPdf"),
      });
    },
    hasDownloaded(event) {
      this.$store.commit("toggleIsDashboardLoading", false);
    },
  },

  computed: {
    chartData() {
      if (this.$store.state.dashboardData == null) return null;
      var data = this.$store.state.dashboardData["medianAgeAtDiagnosisByGNI"];

      var chart = [
        [
          this.$t("widgets.gniMedianDiagnosisAge.gni"),
          this.$t("widgets.gniMedianDiagnosisAge.medianAge"),
          { role: "annotation" },
          { role: "style" },
        ],
      ];

      if (data.length == 0) {
        chart.push(["", 0, "", ""]);
        return chart;
      }

      data.forEach((x, index) => {
        var gniFullName = "";

        switch (x.name) {
          case "H":
            gniFullName = this.$t("filters.grossNationalIncome.high");
            break;

          case "UM":
            gniFullName = this.$t("filters.grossNationalIncome.middleHigh");
            break;

          case "LM":
            gniFullName = this.$t("filters.grossNationalIncome.middleLow");
            break;

          case "L":
            gniFullName = this.$t("filters.grossNationalIncome.low");
            break;
        }

        chart.push([
          gniFullName,
          Math.round(x.result),
          Math.round(x.result) < 1 ? "< 1" : Math.round(x.result) + "",
          "#0067ab",
        ]);
        if (this.maxValue < Math.round(x.result))
          this.maxValue = Math.round(x.result);
      });

      return chart;
    },

    gni() {
      return this.$t("widgets.gniMedianDiagnosisAge.gni");
    },
    medianAge() {
      return this.$t("widgets.gniMedianDiagnosisAge.medianAge");
    },
  },
  watch: {
    chartData(val) {
      if (val != null) this.loading = false;
    },
  },
};
</script>

<template>
  <div class="md_center">
    <img src="/assets/img/Adult-Children.jpg" />
    <div class="md_flex_col pl-2 ml-2">
      <div class="md_typeAB mb-2 pt-2">
        <span class="md_spacer"></span>
        <span>{{ $t("widgets.pedVsAdult.pediatric") }}: </span
        ><span
          v-counter="pediatricData"
          style="font-weight: 900; font-size: 1em"
          >0</span
        ><span style="font-weight: 600; font-size: 1em">%</span>
      </div>
      <div class="md_typeAB">
        <span class="md_spacer"></span>
        <span>{{ $t("widgets.pedVsAdult.adult") }}: </span>
        <span v-counter="adultData" style="font-weight: 900; font-size: 1em"
          >0</span
        ><span style="font-weight: 600; font-size: 1em">%</span>
      </div>
    </div>
  </div>

  <!-- <base-widget
    :titleUnder="true"
    :md="12"
    :sm="4"
    imageName="/assets/img/Adult-Children.jpg"
    :imageMaxWidth="80"
    :imageMinWidth="80"
  >
  
    <template slot="title"></template>
    <template slot="text">
<div class="md_flex_col">
      <div class="md_pediatric">
        <span>Pediatric:</span> <span v-counter="pediatricData" style="font-weight: 600; font-size: 1em;">0</span>%
      </div>

      <div class="md_pediatric">
        <span>Adults:</span> <span v-counter="adultData" style="font-weight: 600; font-size: 1em;">0</span>%
      </div>
</div>
    </template>
  </base-widget> -->
</template>
<script>
import BaseWidget from "./BaseWidget.vue";
import gql from "graphql-tag";
export default {
  components: {
    //baseWidget: BaseWidget
  },
  data() {
    return {
      patientStats: {},
      loading: true,
      chartSwitch: true,
    };
  },
  computed: {
    pediatricData() {
      if (this.$store.state.dashboardData == null) return null;

      var data = this.$store.state.dashboardData["patientsRatio"];
      return Math.round(data.pediatricPatientPercentage);
    },
    adultData() {
      if (this.$store.state.dashboardData == null) return null;

      var data = this.$store.state.dashboardData["patientsRatio"];
      return Math.round(data.adultPatientPercentage);
    },
    patientRatioPieData() {
      if (this.$store.state.dashboardData == null) return null;

      var data = this.$store.state.dashboardData["patientsRatio"];
      var chart = [
        [
          this.$t("widgets.pedVsAdult.ageGroup"),
          this.$t("widgets.pedVsAdult.count"),
        ],
      ];
      chart.push([
        this.$t("widgets.pedVsAdult.pediatric"),
        data.pediatricPatientCount,
      ]);
      chart.push([this.$t("widgets.pedVsAdult.adult"), data.adultPatientCount]);
      return chart;
    },
    patientRatioColumnData() {
      if (this.$store.state.dashboardData == null) return null;
      var data = this.$store.state.dashboardData["patientsRatio"];
      var chart = [
        [
          this.$t("widgets.pedVsAdult.pediatric"),
          this.$t("widgets.pedVsAdult.adult"),
          { role: "annotation" },
        ],
      ];
      chart.push([
        this.$t("widgets.pedVsAdult.pediatric"),
        data.pediatricPatientCount,
        data.pediatricPatientCount,
      ]);
      chart.push([
        this.$t("widgets.pedVsAdult.adult"),
        data.adultPatientCount,
        data.adultPatientCount,
      ]);
      return chart;
    },
    patientsAgeGroup() {
      return this.$t("widgets.pedVsAdult.ageGroup");
    },
    numberOfPatients() {
      return this.$t("widgets.pedVsAdult.numberOfPatients");
    },
    filters() {
      return this.$store.getters.filters;
    },
    filtersRegion() {
      return this.filters.region;
    },
    filtersAge() {
      return this.filters.ageGroup;
    },
    filtersGni() {
      return this.filters.gni;
    },
    filtersRegistrationYears() {
      return this.filters.dateOfRegistration;
    },
  },
  watch: {
    patientRatioPieData(val) {
      if (val != null) this.loading = false;
    },
    patientRatioColumnData(val) {
      if (val != null) this.loading = false;
    },
  },
  // apollo: {
  //   patientStats: {
  //     query: gql`
  //       query PatientStats($regions: [String], $gni: [String]) {
  //         patientRatio(regions: $regions, gni: $gni) {
  //           adultPatientCount
  //           pediatricPatientCount
  //         }
  //       }
  //     `,
  //     variables() {
  //       var filters = this.filters;
  //       var v = {
  //         regions: filters.region,
  //         gni: filters.gni,
  //       };
  //       return v;
  //     },
  //     update: (data) => data.patientRatio,
  //     watchLoading({ loading, key }) {
  //       this.loading = loading;
  //     },
  //   },
  // },
};
</script>

<style scoped>
.md_center img {
  max-height: 80px;
}
.md_center h3 {
  color: #666;
}
.md_center {
  display: flex;
  //justify-content: center;
  align-items: center;
}

.md_flex_col {
  display: flex;
  flex-direction: column;
  padding-left: 2em;
}
</style>
<template>
  <div class="md_center">
    <img src="/assets/img/Blood-drop.jpg" />
    <div class="flex-col">
      <p class="pt-0 ma-0">{{ $t("widgets.annualBleedRate.jointBleeds") }}</p>
      <h3>{{ bleedCount }}</h3>
    </div>
  </div>
  <!-- <base-widget
    :titleUnder="true"
    :md="12"
    :sm="4"
    imageName="/assets/img/Blood-drop.jpg"
    :imageWidth="30"
    :isDense="true"
  >

    <template slot="title">Joint bleeds</template>


    <template slot="text">
      <h1 v-counter="bleedCount" class="text-center">0</h1>
    </template></base-widget
  > -->

</template>
<script>
import gql from "graphql-tag";
import BaseWidget from "./BaseWidget";
export default {
  components: {
    //baseWidget: BaseWidget 
  },
  data() {
    return {
      bleedCountStats: 0,
    };
  },
  computed: {
    bleedCount() {
      if (this.$store.state.dashboardData == null) return 0;
      return this.$store.state.dashboardData["annualJointBleedRate"];
    },
    filters() {
      return this.$store.getters.filters;
    },
    filtersRegion() {
      return this.filters.region;
    },
    filtersAge() {
      return this.filters.ageGroup;
    },
    filtersGni() {
      return this.filters.gni;
    },
    filtersRegistrationYears() {
      return this.filters.dateOfRegistration;
    },
  },
  watch: {
    bleedCount(val) {
      if (val != null) this.loading = false;
    },
  },
  // apollo: {
  //   bleedCountStats: {
  //     query: gql`
  //       query BleedCountStats(
  //         $regions: [String]
  //         $minAge: Int
  //         $maxAge: Int
  //         $gni: [String]
  //         $hemoTypes: [String]
  //         $minRegistrationYear: Int
  //         $maxRegistrationYear: Int
  //       ) {
  //         annualJointBleedRate(
  //           regions: $regions
  //           ageGroup: { min: $minAge, max: $maxAge }
  //           gni: $gni
  //           hemophiliaTypes: $hemoTypes
  //           registrationYear: {
  //             min: $minRegistrationYear
  //             max: $maxRegistrationYear
  //           }
  //         )
  //       }
  //     `,
  //     variables() {
  //       var filters = this.filters;
  //       var v = {
  //         regions: filters.region,
  //         minAge: this.filtersAge.min,
  //         maxAge: this.filtersAge.max,
  //         age: this.filtersAge,
  //         gni: filters.gni,
  //         hemoTypes: filters.hemophiliaType,
  //         minRegistrationYear: this.filtersRegistrationYears.min,
  //         maxRegistrationYear: this.filtersRegistrationYears.max,
  //       };
  //       return v;
  //     },
  //     update: function (data) {
  //       return data.annualJointBleedRate;
  //     },
  //     watchLoading({ loading, etc }) {
  //       this.loading = loading;
  //     },
  //   },
  // },
  created() {
    this.loading = true;
  },
  updated() {
    this.loading = false;
  },
};
</script>

<style scoped>
.md_center img {
  max-width: 30px;
}
.md_center h3 {
  color: #666;
}
.md_center {
  display: flex;
  //justify-content: center;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
  padding-left: 1em;
}
</style>
<template>
  <base-widget :loading="loading" type="graph" :md="12"
    :sm="4">

    <template slot="title">{{
      $t("widgets.patientScholarity.title")
    }}</template>
    <template slot="text">
      <v-switch v-model="chartSwitch"></v-switch>
      <!-- <div class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-circular>
      </div> -->
      <v-slide-y-transition>
        <GChart
          v-if="chartSwitch && !loading"
          :setting="{ packages: ['corechart', 'bar'] }"
          type="PieChart"
          :data="patientScholarityDataPieChart"
          :options="{
            animation: {
              duration: 1500,
              easing: 'out',
              startup: true,
            },
            legend: {
              position: 'right',
              maxLines: '11',
            },
            width: 'auto',
            height: '500',
          }"
        ></GChart>
      </v-slide-y-transition>
      <v-slide-y-transition>
        <GChart
          v-if="!chartSwitch && !loading"
          :setting="{ packages: ['corechart'] }"
          type="ColumnChart"
          :data="patientScholarityData"
          :options="{
            animation: {
              duration: 1500,
              easing: 'out',
              startup: true,
            },
            legend: {
              position: 'none',
            },
            width: 'auto',
            height: '500',
          }"
        ></GChart>
      </v-slide-y-transition>
    </template>
  </base-widget>
</template>
<script>
import BaseWidget from "./BaseWidget.vue";
import gql from "graphql-tag";
export default {
  components: { baseWidget: BaseWidget },
  data() {
    return {
      loading: false,
      patientScholarity: [],
      chartSwitch: true,
    };
  },
  computed: {
    patientScholarityDataPieChart() {
      var chart = [
        [
          this.$t("widgets.patientScholarity.scholarity"),
          this.$t("widgets.patientScholarity.count"),
        ],
      ];

      var patientScholarityCopy = [].concat(this.patientScholarity);
      // The pie chart takes a maximum of 20 "columns". I have aggregated the 20+ year of scholarity togheter.
      // Not perfect, but it works
      patientScholarityCopy = patientScholarityCopy
        .filter((x) => x.scholarYear < 20)
        .concat([
          patientScholarityCopy
            .filter((x) => x.scholarYear >= 20)
            .reduce(
              (acc, cur) => ({
                scholarYear: acc.scholarYear,
                patientCount:
                  Number(acc.patientCount) + Number(cur.patientCount),
              }),
              {
                patientCount: 0,
                scholarYear: "20+",
              }
            ),
        ]);

      patientScholarityCopy.forEach((x) => {
        var title = x.scholarYear + " scholar years";
        chart.push([title, x.patientCount]);
      });

      return chart;
    },
    patientScholarityData() {
      var chart = [
        [
          this.$t("widgets.patientScholarity.scholarity"),
          this.$t("widgets.patientScholarity.count"),
        ],
      ];
      this.patientScholarity.forEach((x) => {
        chart.push([x.scholarYear + " scholar years", x.patientCount]);
      });

      return chart;
    },
  },
  apollo: {
    patientScholarity: {
      query: gql`
        query {
          patientsStats {
            scholarYearStats {
              patientCount
              scholarYear
            }
          }
        }
      `,
      update: function (data) {
        return data.patientsStats.scholarYearStats;
      },
      watchLoading({ loading, key }) {
        this.loading = loading;
      },
    },
  },
  created() {
    this.loading = true;
  },
};
</script>
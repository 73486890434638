var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-widget',{attrs:{"loading":_vm.loading}},[_c('template',{slot:"title"},[_vm._v(_vm._s(this.$t("widgets.patientsByDNAVariantTypes.title")))]),_c('template',{slot:"text"},[_c('v-switch',{attrs:{"color":"accent"},model:{value:(_vm.chartSwitch),callback:function ($$v) {_vm.chartSwitch=$$v},expression:"chartSwitch"}}),_c('v-slide-y-transition',[(_vm.chartSwitch && !_vm.loading)?_c('GChart',{attrs:{"setting":{ package: ['corechart', 'bar'] },"type":"PieChart","data":_vm.patientsByDNAVariantTypesData,"options":{
          animation: {
            duration: 1500,
            easing: 'out',
            startup: true,
          },
          legend: {
            position: 'right',
            maxLines: '5',
          },
          width: 'auto',
          height: '500',
        }}}):_vm._e()],1),_c('v-slide-y-transition',[(!_vm.chartSwitch && !_vm.loading)?_c('GChart',{attrs:{"setting":{ package: ['corechart', 'bar'] },"type":"ColumnChart","data":_vm.patientsByDNAVariantTypesData,"options":{
          animation: {
            duration: 1500,
            easing: 'out',
            startup: true,
          },
          legend: {
            position: 'none',
          },
          width: 'auto',
          height: '500',
        }}}):_vm._e()],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <base-widget
    :loading="loading"
    :titleUnder="isCounter"
    type="counter"
    class="align-self-start"
  >
    <template slot="text">
      <h1 class="text-center">
        <i18n-n :v-counter="counter" :value="counter">0</i18n-n>
      </h1>
    </template>
  </base-widget>
</template>
<script>
import BaseWidget from "./BaseWidget.vue";
export default {
  components: { baseWidget: BaseWidget },
  props: { counter: Number, title: String, loading: Boolean },
  data() {
    return {
      aaacounter: "dsfsfds",
    };
  },
  computed: {
    isCounter() {
      
      return this.aaacounter;
    },
  },
};
</script>
<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="filter-header"
      >{{ this.$t("filters.ageGroup.title") }}
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pt-0 content-wrap-py-0">
      <v-radio-group v-model="ageGroup" @change="filterAgeGroup($event)">
        <!-- <v-radio
          color="primary"
          :label="$t('filters.ageGroup.all')"
          :value="{ min: null, max: null }"
        ></v-radio> -->
        <v-radio
          class="my-0 py-0"
          v-for="ag in ageGroupsValues"
          :key="ag.label"
          color="#0082ed"
          :label="ag.label"
          :value="ag.value"
        ></v-radio>
        <template v-slot:append>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">mdi-help-circle-outline</v-icon> </template
            >{{ $t("filters.ageGroup.tooltipLine1") }}<br />{{
              $t("filters.ageGroup.tooltipLine2")
            }}</v-tooltip
          ></template
        >
      </v-radio-group>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
export default {
  data() {
    return {
      updateTimeout: null,
      ageGroup: "all",
      ageGroupsValues: [
        {
          value: "all",
          label: this.$t("filters.ageGroup.all"),
        },
        { value: "group0", label: this.$t("filters.ageGroup.0") },
        { value: "group1", label: this.$t("filters.ageGroup.1") },
        { value: "group2", label: this.$t("filters.ageGroup.2") },
        { value: "group3", label: this.$t("filters.ageGroup.3") },
        { value: "group4", label: this.$t("filters.ageGroup.4") },
        { value: "group5", label: this.$t("filters.ageGroup.5") },
        { value: "group6", label: this.$t("filters.ageGroup.6") },
      ],
      valueMap: {
        all: { min: null, max: null },
        group0: { min: 0, max: 0 },
        group1: { min: 1, max: 4 },
        group2: { min: 5, max: 17 },
        group3: { min: 18, max: 44 },
        group4: { min: 45, max: 100 },
        group5: { min: 0, max: 17 },
        group6: { min: 18, max: 100 },
      },
    };
  },
  computed: {
    filter() {
      return this.$store.state.filters.ageGroup;
    },
  },
  watch: {
    filter(o) {
      if (o == null) {
        this.ageGroup = "all";
      }
    },
  },
  methods: {
    mapValue(group) {
      return this.valueMap[group];
    },
    applyFilter(ageGroup) {
      if (this.updateTimeout != null) clearTimeout(this.updateTimeout);
      this.updateTimeout = setTimeout(() => {
        const filters = this.$store.state.filters;
        filters.ageGroup = ageGroup;
        this.$store.commit("setFilters", filters);
      }, 300);
    },
    /**
     * @param {Array<{min: number, max: number}>} val
     */
    filterAgeGroup(val) {
      //if (val == null) {
      if (val == "all") {
        // this.ageGroup = [{ min: null, max: null }];
        this.applyFilter(null);
      } else {
        // this.ageGroup = this.ageGroup.filter(
        //   (x) => x.min != null && x.max != null
        // );
        this.applyFilter(this.valueMap[val]);
      }
    },
  },
  created() {},
};
</script>

<style scoped>
.md_subtitle {
  font-size: 0.85em;
}
</style>
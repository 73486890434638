<template>
  <div class="md_center">
    <img src="/assets/img/Adult.jpg" />
    <div class="flex-col">
      <p class="pt-0 ma-0">{{ $t("widgets.annualBleedRate.allBleeds") }}</p>
      <h3>        
        <i18n-n
          v-counter="medianAgeAtFirstBleedData"
          :value="medianAgeAtFirstBleedData"
          >0.0</i18n-n
        >
        {{ $t("widgets.medianBleedAge.yearsOld") }}</h3>
    </div>
  </div>
  <!-- <base-widget
    :titleUnder="true"
    :md="12"
    :sm="4"
    imageName="/assets/img/Adult.jpg"
    :imageWidth="25"
    :isDense="true"
  >
    <template slot="title">All bleeds</template>
    <template slot="text"
      ><h2 class="text-center">
        <i18n-n
          v-counter="medianAgeAtFirstBleedData"
          :value="medianAgeAtFirstBleedData"
          >0.0</i18n-n
        >
        {{ $t("widgets.medianBleedAge.yearsOld") }}
      </h2></template
    >
  </base-widget> -->

  
</template>
<script>
import BaseWidget from "./BaseWidget";
export default {
  components: { 
    //baseWidget: BaseWidget 
    },
  data() {
    return {
      medianAgeAtFirstBleed: "0",
      loading: true,
    };
  },
  computed: {
    medianAgeAtFirstBleedData() {
      if (this.$store.state.dashboardData == null) return 0;
      return Math.round(
        this.$store.state.dashboardData["medianAgeAtFirstBleed"]
      );
    },
    filters() {
      return this.$store.getters.filters;
    },
    filtersRegion() {
      return this.filters.region;
    },
    filtersAge() {
      return this.filters.ageGroup;
    },
    filtersGni() {
      return this.filters.gni;
    },
    filtersRegistrationYears() {
      return this.filters.dateOfRegistration;
    },
  },
  watch: {
    medianAgeAtFirstBleedData(val) {
      if (val != null) this.loading = false;
    },
  },
  created() {
    this.loading = true;
  },
  updated() {
    this.loading = false;
  },
};
</script>

<style scoped>
.md_center img {
  margin-left: 0.5em;
  max-width: 20px;
}
.md_center h3 {
  color: #666;
}
.md_center {
  display: flex;
  //justify-content: center;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
  padding-left: 1em;
}
</style>
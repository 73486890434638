<template>
    <section>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            filename="Registered people with hemophilia over time"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="landscape"
            pdf-content-width="1260px"
            @hasDownloaded="hasDownloaded($event)"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                <!-- PDF Content Here -->
                <base-widget :loading="loading" type="graph" :md="12" :sm="12">
                    <template slot="title">{{
                        $t("widgets.registeredPatientsOverTime.title")
                    }}</template>
                    <template slot="text">
                        <GChart
                            v-if="!loading"
                            :setting="{ packages: ['corechart'] }"
                            type="ColumnChart"
                            :data="data"
                            :options="{
                                animation: {
                                    duration: 1500,
                                    easing: 'out',
                                    startup: true,
                                },
                                legend: {
                                    position: 'none',
                                },

                                vAxis: {
                                    title: registeredPatients,
                                    titleTextStyle: {
                                        bold: true,
                                        italic: false,
                                    },
                                    gridlines: {
                                        count: 10,
                                    },
                                    viewWindow: {
                                        min: 0,
                                        max: highestValue,
                                    },
                                    viewWindowMode: 'explicit',
                                },
                                hAxis: {
                                    title: yearsOverTime,
                                    titleTextStyle: {
                                        bold: false,
                                        italic: false,
                                    },
                                },
                                annotations: {
                                    alwaysOutside: true,
                                    textStyle: {
                                        opacity: 1,
                                    },
                                    stem: {
                                        length: 0,
                                    },
                                },
                                width: 'auto',
                                height: '500',
                            }"
                        ></GChart>
                    </template>
                </base-widget>
            </section>
        </vue-html2pdf>

        <base-widget :loading="loading" type="graph" :md="12" :sm="12">
            <template slot="title">{{
                $t("widgets.registeredPatientsOverTime.title")
            }}</template>
            <template slot="text">
                <GChart
                    v-if="!loading"
                    :setting="{ packages: ['corechart'] }"
                    type="ColumnChart"
                    :data="data"
                    :options="{
                        animation: {
                            duration: 1500,
                            easing: 'out',
                            startup: true,
                        },
                        legend: {
                            position: 'none',
                        },

                        vAxis: {
                            title: registeredPatients,
                            titleTextStyle: {
                                bold: true,
                                italic: false,
                            },
                            gridlines: {
                                count: 10,
                            },
                            viewWindow: {
                                min: 0,
                                max: highestValue,
                            },
                            viewWindowMode: 'explicit',
                        },
                        hAxis: {
                            title: yearsOverTime,
                            titleTextStyle: {
                                bold: false,
                                italic: false,
                            },
                        },
                        annotations: {
                            alwaysOutside: true,
                            textStyle: {
                                opacity: 1,
                            },
                            stem: {
                                length: 0,
                            },
                        },
                        width: 'auto',
                        height: '500',
                    }"
                ></GChart>
            </template>
        </base-widget>
        <div class="dl">
            <input
                type="image"
                src="assets/img/ikon.png"
                @click="generateReport"
            />
        </div>
    </section>
</template>
<script>
import BaseWidget from "./BaseWidget.vue";
import VueHtml2pdf from "vue-html2pdf";

export default {
    components: { baseWidget: BaseWidget, VueHtml2pdf },
    data() {
        return {
            patientsStats: [],
            loading: true,
            maxValue: 0,
        };
    },

    methods: {
        generateReport() {
            this.startPagination();
            this.$refs.html2Pdf.generatePdf();
        },
        startPagination() {
            this.$store.commit("toggleIsDashboardLoadingCustomText", {
                toggle: true,
                text: this.$t("app.generatingPdf"),
            });
        },
        hasDownloaded(event) {
            this.$store.commit("toggleIsDashboardLoading", false);
        },
    },
    computed: {
        highestValue() {
            let v = [];
            for (const x in this.$store.state.dashboardData
                .patientByYearOfRegistration) {
                v.push(
                    this.$store.state.dashboardData.patientByYearOfRegistration[
                        x
                    ].patientCount
                );
            }
            return Math.max(...v) + 250;
        },

        data() {
            if (this.$store.state.dashboardData == null) return null;
            var data = this.$store.state.dashboardData[
                "patientByYearOfRegistration"
            ];
            var chart = [
                [
                    this.$t("widgets.registeredPatientsOverTime.year"),
                    this.$t(
                        "widgets.registeredPatientsOverTime.registeredPatients"
                    ),
                    { role: "annotation" },
                    { role: "style" },
                ],
            ];

            if (data.length == 0) {
                chart.push(["", 0, "", ""]);
                return chart;
            }

            data.forEach((x) => {
                if (x.yearOfRegistration >= 2018) {
                    chart.push([
                        `${x.yearOfRegistration}`,
                        x.patientCount,
                        `${x.patientCount}`,
                        "#0067ab",
                    ]);
                    if (this.maxValue < Math.round(x.patientCount))
                        this.maxValue = Math.round(x.patientCount);
                }
            });
            return chart;
        },
        yearsOverTime() {
            return this.$t("widgets.registeredPatientsOverTime.years");
        },
        registeredPatients() {
            return this.$t(
                "widgets.registeredPatientsOverTime.registeredPatients"
            );
        },
        filters() {
            return this.$store.getters.filters;
        },
        filtersRegion() {
            return this.filters.region;
        },
        filtersAge() {
            return this.filters.ageGroup;
        },
        filtersGni() {
            return this.filters.gni;
        },
        filtersHemoTypes() {
            return this.filters.hemophiliaType;
        },
        filtersRegistrationYears() {
            return this.filters.dateOfRegistration;
        },
    },
    watch: {
        data(val) {
            if (val != null) this.loading = false;
        },
    },
    created() {
        this.loading = true;
    },
    updated() {
        this.loading = false;
    },
};
</script>

<template>
  <div class="md_center">
    <img src="/assets/img/Blood-drop.jpg" />
    <div class="flex-col">
      <p>{{ $t("widgets.medianDaysPerHospitalization.title") }}</p>
      <h3>{{ medianDaysPerHospitalizationData }}</h3>
    </div>
  </div>
</template>




<script>
// import BaseWidget from "./BaseWidget";
export default {
  // components: { baseWidget: BaseWidget },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    medianDaysPerHospitalizationData() {
      if (this.$store.state.dashboardData == null) return 0;
      return Math.round(
        this.$store.state.dashboardData["medianDaysPerHopitalization"]
      );
    },
    filters() {
      return this.$store.getters.filters;
    },
    filtersRegion() {
      return this.filters.region;
    },
    filtersAge() {
      return this.filters.ageGroup;
    },
    filtersGni() {
      return this.filters.gni;
    },
    filtersRegistrationYears() {
      return this.filters.dateOfRegistration;
    },
  },
  watch: {
    medianDaysPerHospitalizationData(val) {
      if (val != null) this.loading = false;
    },
  },
  created() {
    this.loading = true;
  },
  updated() {
    this.loading = false;
  },
};
</script>




<style scoped>
.md_center img {
  max-width: 30px;
}
.md_center h3 {
  color: #666;
}
.md_center {
  display: flex;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
  padding-left: 1em;
}
</style>
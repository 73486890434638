<template>
  <section>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="Number of people with hemophilia by GNI"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="800px"
      @hasDownloaded="hasDownloaded($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <!-- PDF Content Here -->
        <base-widget :loading="loading" type="graph" :md="12" :sm="4">
          <template slot="title">
            {{ this.$t("widgets.patientCountByGNI.title") }}</template
          >

          <template slot="text">
            <v-switch v-model="chartSwitch" color="#5b1b6e"></v-switch>

            <v-slide-y-transition>
              <GChart
                class="pa-1"
                v-if="chartSwitch && !loading"
                :setting="{ packages: ['corechart', 'bar'] }"
                type="PieChart"
                :data="chartData"
                :options="{
                  pieHole: 0.4,
                  animation: {
                    duration: 1500,
                    easing: 'out',
                    startup: true,
                  },
                  chartArea: {
                    width: '100%',
                    height: '100%',
                  },
                  legend: {
                    position: 'right',
                    textStyle: {
                      fontSize: `${legendFontSize}`,
                    },
                  },
                  height: '300',
                  colors: [
                    '#8c1f70',
                    '#009fdb',
                    '#5b1b6e',
                    '#b91b66',
                    '#123175',
                  ],
                  sliceVisibilityThreshold: 0,
                }"
              ></GChart>
            </v-slide-y-transition>
            <v-slide-y-transition>
              <GChart
                v-if="!chartSwitch && !loading"
                :setting="{ packages: ['corechart'] }"
                type="ColumnChart"
                :data="chartData"
                :options="{
                  animation: {
                    duration: 1500,
                    easing: 'out',
                    startup: true,
                  },
                  legend: {
                    position: 'none',
                  },
                  vAxis: {
                    title: patientCount,
                    titleTextStyle: {
                      bold: true,
                      italic: false,
                    },
                    gridlines: {
                      count: 10,
                    },
                    viewWindow: {
                      min: 0,
                      max: highestValue,
                    },
                    viewWindowMode: 'explicit',
                  },
                  hAxis: {
                    title: gni,
                    titleTextStyle: {
                      bold: true,
                      italic: false,
                    },
                  },
                  annotations: {
                    alwaysOutside: true,
                    textStyle: {
                      opacity: 1,
                    },
                    stem: {
                      length: 0,
                    },
                  },
                  width: 'auto',
                  height: '500',
                }"
              ></GChart>
            </v-slide-y-transition>
          </template>
        </base-widget>
      </section>
    </vue-html2pdf>

    <base-widget :loading="loading" type="graph" :md="12" :sm="4">
      <template slot="title">
        {{ this.$t("widgets.patientCountByGNI.title") }}</template
      >

      <template slot="text">
        <v-switch v-model="chartSwitch" color="#5b1b6e"></v-switch>

        <v-slide-y-transition>
          <GChart
            class="pa-1"
            v-if="chartSwitch && !loading"
            :setting="{ packages: ['corechart', 'bar'] }"
            type="PieChart"
            :data="chartData"
            :options="{
              pieHole: 0.4,
              animation: {
                duration: 1500,
                easing: 'out',
                startup: true,
              },
              chartArea: {
                width: '100%',
                height: '100%',
              },
              legend: {
                position: 'right',
                textStyle: {
                  fontSize: `${legendFontSize}`,
                },
              },
              height: '300',
              colors: ['#8c1f70', '#009fdb', '#5b1b6e', '#b91b66', '#123175'],
              sliceVisibilityThreshold: 0,
            }"
          ></GChart>
        </v-slide-y-transition>
        <v-slide-y-transition>
          <GChart
            v-if="!chartSwitch && !loading"
            :setting="{ packages: ['corechart'] }"
            type="ColumnChart"
            :data="chartData"
            :options="{
              animation: {
                duration: 1500,
                easing: 'out',
                startup: true,
              },
              legend: {
                position: 'none',
              },
              vAxis: {
                title: patientCount,
                titleTextStyle: {
                  bold: true,
                  italic: false,
                },
                gridlines: {
                  count: 10,
                },
                viewWindow: {
                  min: 0,
                  max: highestValue,
                },
                viewWindowMode: 'explicit',
              },
              hAxis: {
                title: gni,
                titleTextStyle: {
                  bold: true,
                  italic: false,
                },
              },
              annotations: {
                alwaysOutside: true,
                textStyle: {
                  opacity: 1,
                },
                stem: {
                  length: 0,
                },
              },
              width: 'auto',
              height: '500',
            }"
          ></GChart>
        </v-slide-y-transition>
      </template>
    </base-widget>
    <div class="dl">
      <input type="image" src="assets/img/ikon.png" @click="generateReport" />
    </div>
  </section>
</template>
<script>
import BaseWidget from "./BaseWidget.vue";
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: {
    baseWidget: BaseWidget,
    VueHtml2pdf,
  },
  data() {
    return {
      loading: true,
      patientStats: [],
      maxValue: 0,
      chartSwitch: false,
      patientEmployement: [],
    };
  },
  methods: {
    generateReport() {
      this.startPagination();
      this.$refs.html2Pdf.generatePdf();
    },
    startPagination() {
      this.$store.commit("toggleIsDashboardLoadingCustomText", {
        toggle: true,
        text: this.$t("app.generatingPdf"),
      });
    },
    hasDownloaded(event) {
      this.$store.commit("toggleIsDashboardLoading", false);
    },
  },
  computed: {
    highestValue() {
      let v = [];
      for (const x in this.$store.state.dashboardData.patientCountByGNI) {
        v.push(
          this.$store.state.dashboardData.patientCountByGNI[x].patientCount
        );
      }
      return Math.max(...v) + 250; 
    },

    chartData() {
      if (this.$store.state.dashboardData == null) return null;
      var data = this.$store.state.dashboardData["patientCountByGNI"];

      // console.table(data);

      var chart = [
        [
          this.$t("widgets.patientCountByGNI.gni"),
          this.$t("widgets.patientCountByGNI.patientCount"),
          { role: "annotation" },
          { role: "style" },
        ],
      ];

      if (data.length == 0) {
        chart.push(["", 0, "", ""]);
        return chart;
      }

      data.forEach((x, index) => {
        var gniFullName = "";

        switch (x.gniName) {
          case "L":
            gniFullName = this.$t("filters.grossNationalIncome.low");
            break;
          case "LM":
            gniFullName = this.$t("filters.grossNationalIncome.middleLow");
            break;
          case "UM":
            gniFullName = this.$t("filters.grossNationalIncome.middleHigh");
            break;
          case "H":
            gniFullName = this.$t("filters.grossNationalIncome.high");
            break;
        }

        chart.push([
          gniFullName,
          Math.round(x.patientCount),
          Math.round(x.patientCount) < 1
            ? "< 1"
            : Math.round(x.patientCount) + "",
          "#0067ab",
        ]);
        // if (this.maxValue < Math.round(x.patientCount)) this.maxValue = Math.round(x.patientCount);
        // on calcule maxValue dans une computed, pour updater la plus haute valeur à chaque changement de filtre
      });
      return chart;
    },
    gni() {
      return this.$t("widgets.patientCountByGNI.gni");
    },
    patientCount() {
      return this.$t("widgets.patientCountByGNI.patientCount");
    },

    legendFontSize() {
      if (this.$vuetify.breakpoint.name == "xl") return 16;
      return 12;
    },
  },
  watch: {
    chartData(val) {
      if (val != null) this.loading = false;
    },
  },
};
</script>

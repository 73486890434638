var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('section',[_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"float-layout":true,"enable-download":true,"preview-modal":true,"paginate-elements-by-height":1400,"filename":"Median age at diagnosis by region","pdf-quality":2,"manual-pagination":false,"pdf-format":"a4","pdf-orientation":"landscape","pdf-content-width":"800px"},on:{"hasDownloaded":function($event){return _vm.hasDownloaded($event)}}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('base-widget',{attrs:{"loading":_vm.loading,"type":"graph","md":12,"sm":4}},[_c('template',{slot:"title"},[_vm._v("Median age at diagnosis by region")]),_c('template',{slot:"text"},[_c('v-slide-y-transition',[(!_vm.loading)?_c('GChart',{attrs:{"setting":{ packages: ['corechart'] },"type":"ColumnChart","data":_vm.chartData,"options":{
                  animation: {
                    duration: 1500,
                    easing: 'out',
                    startup: true,
                  },
                  legend: {
                    position: 'none',
                  },
                  vAxis: {
                    title: _vm.patientWithProphylaxisPercentage,
                    titleTextStyle: {
                      bold: true,
                      italic: false,
                    },
                    gridlines: {
                      count: 10,
                    },
                    viewWindow: {
                      min: 0,
                      max: _vm.maxValue + 5,
                    },
                    viewWindowMode: 'explicit',
                  },
                  hAxis: {
                    title: _vm.region,
                    titleTextStyle: {
                      bold: true,
                      italic: false,
                    },
                  },
                  annotations: {
                    alwaysOutside: true,
                    textStyle: {
                      opacity: 1,
                    },
                    stem: {
                      length: 0,
                    },
                  },
                  width: 'auto',
                  height: '500',
                }}}):_vm._e()],1)],1)],2)],1)]),_c('base-widget',{attrs:{"loading":_vm.loading,"type":"graph","md":12,"sm":4}},[_c('template',{slot:"title"},[_vm._v(_vm._s(this.$t("widgets.prophylaxisRatioByRegion.title")))]),_c('template',{slot:"text"},[_c('v-slide-y-transition',[(!_vm.loading)?_c('GChart',{attrs:{"setting":{ packages: ['corechart'] },"type":"ColumnChart","data":_vm.chartData,"options":{
              animation: {
                duration: 1500,
                easing: 'out',
                startup: true,
              },
              legend: {
                position: 'none',
              },
              vAxis: {
                title: _vm.patientWithProphylaxisPercentage,
                titleTextStyle: {
                  bold: true,
                  italic: false,
                },
                gridlines: {
                  count: 10,
                },
                viewWindow: {
                  min: 0,
                  max: _vm.maxValue + 5,
                },
                viewWindowMode: 'explicit',
              },
              hAxis: {
                title: _vm.region,
                titleTextStyle: {
                  bold: true,
                  italic: false,
                },
              },
              annotations: {
                alwaysOutside: true,
                textStyle: {
                  opacity: 1,
                },
                stem: {
                  length: 0,
                },
              },
              width: 'auto',
              height: '500',
            }}}):_vm._e()],1)],1)],2)],1),_c('div',{staticClass:"dl"},[_c('input',{attrs:{"type":"image","src":"assets/img/ikon.png"},on:{"click":_vm.generateReport}})])])}
var staticRenderFns = []

export { render, staticRenderFns }
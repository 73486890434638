<template>
  <counter
    :title="$t('widgets.averageBleedAge.title')"
    :counter="averageAgeAtFirstBleedData"
  ></counter>
</template>
<script>
import gql from "graphql-tag";
import BaseCounterWidget from "./BaseCounterWidget";

export default {
  components: { counter: BaseCounterWidget },
  data() {
    return {
      averageAgeAtFirstBleed: "0",
      loading: true,
    };
  },
  computed: {
    averageAgeAtFirstBleedData() {
      if (this.$store.state.dashboardData == null) return 0;
      return Math.round(
        this.$store.state.dashboardData["averageAgeAtFirstBleed"]
      );
    },

    filters() {
      return this.$store.getters.filters;
    },
    filtersRegion() {
      return this.filters.region;
    },
    filtersAge() {
      return this.filters.ageGroup;
    },
    filtersGni() {
      return this.filters.gni;
    },
    filtersRegistrationYears() {
      return this.filters.dateOfRegistration;
    },
  },
  watch: {
    averageAgeAtFirstBleedData(val) {
      if (val != null) this.loading = false;
    },
  },
  // apollo: {
  //   averageAgeAtFirstBleed: {
  //     query() {
  //       return gql`
  //         query AverageAgeAtFirstBleed(
  //           $regions: [String]
  //           $minAge: Int
  //           $maxAge: Int
  //           $gni: [String]
  //           $hemoTypes: [String]
  //           $minRegistrationYear: Int
  //           $maxRegistrationYear: Int
  //         ) {
  //           averageAgeAtFirstBleedStoredProc(
  //             regions: $regions
  //             ageGroup: { min: $minAge, max: $maxAge }
  //             gni: $gni
  //             hemophiliaTypes: $hemoTypes
  //             registrationYear: {
  //               min: $minRegistrationYear
  //               max: $maxRegistrationYear
  //             }
  //           )
  //         }
  //       `;
  //     },
  //     variables() {
  //       var filters = this.filters;
  //       var v = {
  //         regions: filters.region,
  //         minAge: this.filtersAge.min,
  //         maxAge: this.filtersAge.max,
  //         age: this.filtersAge,
  //         gni: filters.gni,
  //         hemoTypes: filters.hemophiliaType,
  //         minRegistrationYear: this.filtersRegistrationYears.min,
  //         maxRegistrationYear: this.filtersRegistrationYears.max,
  //       };
  //       return v;
  //     },
  //     update: function (data) {
  //       return data.averageAgeAtFirstBleedStoredProc;
  //     },
  //     watchLoading({ loading, etc }) {
  //       this.loading = loading;
  //     },
  //   },
  // },
  created() {
    this.loading = true;
  },
  updated() {
    this.loading = false;
  },
};
</script>
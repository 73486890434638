<template>
  <v-col md="12" :sm="12" class="pa-2">
    <!-- <v-card
      elevation="0"
      height="80%"
      style="background-color: #009fea"
      max-width="100%"
    > -->
      <v-card elevation="0" width="100%" max-width="100%" class="mdpushup">
        <v-card-text class="mt-5">
          {{ $t("widgets.patientsByCountry.title") }}
          <v-card-text>
            <GChart
              v-if="!loading"
              :settings="{
                packages: ['geochart'],
                mapsApiKey: '',
              }"
              type="GeoChart"
              :data="patientsByCountryData"
              :options="{
                colorAxis: {
                  minValue: 0,
                  colors: ['#009fea', '#0067ab', '#183474', '#5b1a6e'],
                },
                datalessRegionColor: '#ededed',
                magnifyingGlass: {
                  enable: true,
                  zoomFactor: 1.5,
                },
              }"
            ></GChart>
          </v-card-text>
        </v-card-text>
      </v-card>
    <!-- </v-card> -->
  </v-col>
</template>
  
<script>
//import BaseWidget from "./BaseWidget.vue";

export default {
  //components: { baseWidget: BaseWidget },
  data() {
    return {
      patientsByCountries: [],
      loading: true,
    };
  },
  computed: {
    patientsByCountryData() {
      if (this.$store.state.dashboardData == null) return null;
      var data = this.$store.state.dashboardData["countryPatientCounts"];
      var chart = [
        [
          this.$t("widgets.patientsByCountry.country"),
          this.$t("widgets.patientsByCountry.count"),
        ],
      ];

      data.forEach((x) => {
        chart.push([{ v: x.countryCode, f: x.countryName }, x.patientCount]);
      });

      return chart;
    },
    filters() {
      return this.$store.getters.filters;
    },
    filtersRegion() {
      return this.filters.region;
    },
    filtersAge() {
      var filter = this.filters.ageGroup;
      return filter;
    },
    filtersGni() {
      return this.filters.gni;
    },
    filtersHemoTypes() {
      return this.filters.hemophiliaType;
    },
    filtersRegistrationYears() {
      var dateOfRegistration = this.filters.dateOfRegistration;
      return dateOfRegistration;
    },
  },
  watch: {
    patientsByCountryData(val) {
      if (val != null) this.loading = false;
    },
  },
  created() {
    this.loading = true;
  },
  updated() {
    this.loading = false;
  },
};
</script>
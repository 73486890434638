var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pa-2",attrs:{"md":"12","sm":12}},[_c('v-card',{staticClass:"mdpushup",attrs:{"elevation":"0","width":"100%","max-width":"100%"}},[_c('v-card-text',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.$t("widgets.patientsByCountry.title"))+" "),_c('v-card-text',[(!_vm.loading)?_c('GChart',{attrs:{"settings":{
              packages: ['geochart'],
              mapsApiKey: '',
            },"type":"GeoChart","data":_vm.patientsByCountryData,"options":{
              colorAxis: {
                minValue: 0,
                colors: ['#009fea', '#0067ab', '#183474', '#5b1a6e'],
              },
              datalessRegionColor: '#ededed',
              magnifyingGlass: {
                enable: true,
                zoomFactor: 1.5,
              },
            }}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
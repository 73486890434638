<template>
  <div>
    <v-card class="mx-auto pr-4" max-width="400">
      <div class="overline ml-2">Filtered By:</div>
      <v-card
        v-for="filter in mappedFilters"
        :key="filter.key"
        class="pb-3 pl-2"
        elevation="0"
      >
        <v-card-text class="py-1">{{ filter.label }}:</v-card-text>
        <v-chip
          v-for="val in filter.values"
          :key="val.label"
          close
          class="ma-1"
          @click:close="removeFilter(filter.key, val.key)"
          pill
          outlined
          color="#616265"
          ><span class="text-truncate">{{ val.label }}</span></v-chip
        >
      </v-card>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    filters() {
      const filters = this.$store.state.filters;
      const keys = Object.keys(filters).filter((k) => filters[k] != null);
      return keys;
    },
    mappedFilters() {
      var filters = this.$store.getters.rawFilters;
      var keys = Object.keys(filters);
      var map = {};
      keys.forEach((f) => {
        if (f != "htcName") map[f] = { key: f, label: this.translateFilter(f) };

        switch (f) {
          case "ageGroup":
          case "dateOfRegistration":
            map[f].values = [
              { key: filters[f], label: `${filters[f].min}-${filters[f].max}` },
            ];
            break;
          case "htcId":
            map[f].values = [{ key: filters[f], label: filters["htcName"] }];
            break;
          case "htcName":
            break;
          case "gni":
            map[f].values = filters[f].map((x) => ({
              key: x,
              label: this.$t(`filters.grossNationalIncome.${x}`),
            }));
            break;
          case "hemophiliaType":
            map[f].values = filters[f].map((x) => ({
              key: x,
              label: this.$t(`filters.hemophiliaType.type${x}`),
            }));
            break;
          case "region":
          default:
            map[f].values = filters[f].map((x) => ({ key: x, label: x }));
            break;
        }
      });

      return map;
    },
  },
  methods: {
    translateFilter(filterKey) {
      switch (filterKey) {
        case "gni":
          filterKey = "grossNationalIncome";
          break;
      }
      return this.$t(`filters.${filterKey}.title`);
    },
    removeFilter(filterKey, filterValue) {
      var filters = this.$store.state.filters;
      switch (filterKey) {
        case "ageGroup":
          filters[filterKey] = null;
          break;
        case "dateOfRegistration":
          filters[filterKey] = null;
          break;
        case "gni":
          filters[filterKey] = filters[filterKey].filter(
            (x) => x != filterValue
          );

          if (filters[filterKey].length == 0) {
            filters[filterKey] = null;
          }
          break;
        case "hemophiliaType":
          filters[filterKey] = filters[filterKey].filter(
            (x) => x != filterValue
          );

          if (filters[filterKey].length == 0) {
            filters[filterKey] = null;
          }
          break;
        case "region":
          filters[filterKey] = filters[filterKey].filter(
            (x) => x != filterValue
          );

          if (filters[filterKey].length == 0) {
            filters[filterKey] = null;
          }
          break;
        case "htcId":
          filters[filterKey] = null;
          filters["htcName"] = null;
          break;
        default:
          filters[filterKey] = filters[filterKey].filter(
            (x) => x != filterValue
          );

          if (filters[filterKey].length == 0) {
            filters[filterKey] = null;
          }
          break;
      }

      //   filters[filterKey] = null;
      this.$store.commit("setFilters", filters);
    },
  },
};
</script>
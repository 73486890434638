<template>
  <!-- <base-widget
    :loading="loading"
    :md="12"
    :sm="4"
    :titleUnder="true"
    imageName="/assets/img/htc.jpg"
    :imageWidth="80"
  >
    <template slot="title">{{ $t("widgets.htcCount.title") }}</template>
    <template slot="text">
      <h1 class="text-center">
        <i18n-n v-counter="htcCountData" :value="htcCountData">0</i18n-n>
      </h1>
    </template>
  </base-widget> -->

  

  <!-- NEW LAYOUT 25 OCTOBER 2021 -Mario -->
  <div class="md_center">
    <img src="/assets/img/htc.jpg" />
    <div class="flex-col">
      <p>{{ $t("widgets.htcCount.title") }}</p>
      <h3>{{ htcCountData }}</h3>
    </div>
  </div>


</template>



<script>
import gql from "graphql-tag";
// import BaseWidget from "./BaseWidget.vue";
export default {
  // components: { baseWidget: BaseWidget },
  data() {
    return {
      htcStats: [],
      loading: true,
    };
  },
  computed: {
    htcCountData() {
      if (this.$store.state.dashboardData == null) return 0;
      return this.$store.state.dashboardData["participatingHTCCount"];
    },
  },
  watch: {
    htcCountData(val) {
      if (val != null) this.loading = false;
    },
  },
  created() {
    this.loading = true;
  },
  updated() {
    this.loading = false;
  },
  // watch: {
  //   htcCount: function () {
  //     this.counterHelper(this.htcCount, this.$refs["counter"]);
  //   },
  // },
  // apollo: {
  //   htcStats: {
  //     //   query: gql`
  //     //     query {
  //     //       htcStats {
  //     //         htcCount
  //     //       }
  //     //     }
  //     //   `,
  //     query: gql`
  //       query NMOData($regions: [String], $gni: [String]) {
  //         nmoData(regions: $regions, gni: $gni) {
  //           htcCount
  //         }
  //       }
  //     `,
  //     variables() {
  //       return {
  //         regions: this.$store.state.filters.region,
  //         gni: this.$store.state.filters.gni,
  //       };
  //     },
  //     watchLoading({ loading, key }) {
  //       this.loading = loading;
  //     },
  //     update: (data) => data.nmoData,
  //   },
  // },
};
</script>






<style scoped>
.md_center img {
  max-height: 60px;
}
.md_center h3 {
  color: #666;
}
.md_center {
  display: flex;
  //justify-content: center;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}
</style>
<template>
  <counter
    :title="$t('widgets.inhibitorsUniquePatientCount.title')"
    :counter="inhibitorUniquePatientCount"
    :loading="loading"
  ></counter>
</template>

<script>
import gql from "graphql-tag";
import BaseCounterWidget from "./BaseCounterWidget";
export default {
  components: { counter: BaseCounterWidget },
  data() {
    return {
      inhibitorsStats: 0,
      loading: true,
    };
  },
  computed: {
    inhibitorUniquePatientCount() {
      if (this.$store.state.dashboardData == null) return 0;
      return this.$store.state.dashboardData["uniqueInhibitorTestCount"];
    },
    filters() {
      return this.$store.getters.filters;
    },
    filtersRegion() {
      return this.filters.region;
    },
    filtersAge() {
      return this.filters.ageGroup;
    },
    filtersGni() {
      return this.filters.gni;
    },
    filtersRegistrationYears() {
      return this.filters.dateOfRegistration;
    },
  },
  watch: {
    inhibitorUniquePatientCount(val) {
      if (val != null) this.loading = false;
    },
  },
  // watch: {
  //   htcCount: function () {
  //     this.counterHelper(this.htcCount, this.$refs["counter"]);
  //   },
  // },
  // apollo: {
  //   inhibitorsStats: {
  //     query: gql`
  //       query InhibitorsStats(
  //         $regions: [String]
  //         $minAge: Int
  //         $maxAge: Int
  //         $gni: [String]
  //         $hemoTypes: [String]
  //         $minRegistrationYear: Int
  //         $maxRegistrationYear: Int
  //         $unique: Boolean
  //       ) {
  //         inhibitorCount(
  //           regions: $regions
  //           ageGroup: { min: $minAge, max: $maxAge }
  //           gni: $gni
  //           hemophiliaTypes: $hemoTypes
  //           registrationYear: {
  //             min: $minRegistrationYear
  //             max: $maxRegistrationYear
  //           }
  //           unique: $unique
  //         )
  //       }
  //     `,
  //     variables() {
  //       var filters = this.filters;
  //       var v = {
  //         regions: filters.region,
  //         gni: filters.gni,
  //         unique: true,
  //       };
  //       return v;
  //     },
  //     update: (data) => data.inhibitorCount,
  //     watchLoading({ loading, key }) {
  //       this.loading = loading;
  //     },
  //   },
  // },
};
</script>
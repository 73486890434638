<template>
  <!--<base-widget :titleUnder="false" :md="12" :sm="4" imageName="sdf">
    <template slot="title"></template>
    <template slot="text">    
     <div class="md_center">
      <v-img
        src="assets/img/hemophilia_A.jpg"
        max-width="35"
        class="mr-1"
      ></v-img>
      <v-img
        src="assets/img/hemophilia_B.jpg"
        max-width="35"
        class="mr-1"
      ></v-img>      
      <div class="md_flex_col pl-2">
        <div class="md_typeAB mb-5 mt-2"><span class="md_spacer"></span>
          <span>{{ $t("widgets.hemophiliaTypeRatio.typeA") }}: </span
          ><span v-counter="typeAData"  style="font-weight: 900; font-size: 1em;">0</span><span  style="font-weight: 600; font-size: 1em;">%</span>
        </div>
        <div class="md_typeAB"><span class="md_spacer"></span>
          <span>{{ $t("widgets.hemophiliaTypeRatio.typeB") }}: </span
          ><span v-counter="typeBData"  style="font-weight: 900; font-size: 1em;">0</span><span  style="font-weight: 600; font-size: 1em;">%</span>
        </div>      
      </div>
    </div>
    </template></base-widget>-->
    <div class="md_center">
      <img src="/assets/img/hemophilia_A.jpg" class="mr-2" />
      <img src="/assets/img/hemophilia_B.jpg" class="mr-2" />
      <div class="md_flex_col pl-2">
        <div class="md_typeAB mb-2 pt-2"><span class="md_spacer"></span>
          <span>{{ $t("widgets.hemophiliaTypeRatio.typeA") }}: </span
          ><span v-counter="typeAData"  style="font-weight: 900; font-size: 1em;">0</span><span  style="font-weight: 600; font-size: 1em;">%</span>
        </div>
        <div class="md_typeAB"><span class="md_spacer"></span>
          <span>{{ $t("widgets.hemophiliaTypeRatio.typeB") }}: </span>
          <span v-counter="typeBData"  style="font-weight: 900; font-size: 1em;">0</span><span  style="font-weight: 600; font-size: 1em;">%</span>
        </div>      
      </div>
    </div>
</template>
<script>
import BaseWidget from "./BaseWidget.vue";
export default {
  components: { 
    //BaseWidget: BaseWidget 
  },
  data() {
    return {};
  },
  computed: {
    typeAData() {
      if (this.$store.state.dashboardData == null) return null;
      var data = this.$store.state.dashboardData["hemophiliaTypeRatio"];
      var typeAPercentage = Math.round(
        (data.typeACount / (data.typeACount + data.typeBCount)) * 100
      );
      return typeAPercentage;
    },
    typeBData() {
      if (this.$store.state.dashboardData == null) return null;
      var data = this.$store.state.dashboardData["hemophiliaTypeRatio"];
      var typeBPercentage = Math.round(
        (data.typeBCount / (data.typeACount + data.typeBCount)) * 100
      );
      return typeBPercentage;
    },
  },
};
</script>

<style scoped>

.md_center img {
  max-height: 75px;
}
.md_center h3 {
  color: #666;
}
.md_center {
  display: flex;
  //justify-content: center;
  align-items: center;
}

.md_flex_col {
  display: flex;
  flex-direction: column; /* Juste pour éviter d'avoir les 2 divs sur la même ligne */
}

</style>
<template>
  <base-widget
    :titleUnder="true"
    :md="12"
    :sm="4"
    imageName="/assets/img/Blood-drop.jpg"
    :imageWidth="65"
  >
    <template slot="title">{{
      $t("widgets.averageBleedsPerPatientWidget.title")
    }}</template>
    <template slot="text">
      <!-- <h1 v-counter="averageBleed" class="text-center"></h1>   -->
   <div class="lil_box">
    <span class="md_item">On prophylaxis:</span><span class="md_span" v-counter="AverageBleedsPerPatientsWithProphylaxis"></span>
   </div>

   <div class="lil_box">
    <span class="md_item">Not on prophylaxis:</span><span class="md_span" v-counter="AverageBleedsPerPatientsWithoutProphylaxis"></span>
   </div>
    </template>
   </base-widget>
   
</template>



<script>
import gql from "graphql-tag";
import BaseWidget from "./BaseWidget";
export default {
  components: { baseWidget: BaseWidget },
  data() {
    return {
      averageBleedStats: "0.0",
    };
  },
  computed: {
    averageBleed() {
      if (this.$store.state.dashboardData == null) return 0;
      return Math.round(
        this.$store.state.dashboardData["averageBleedsPerPatients"]
      );

    // We split the original averageBleed into 2 new numbers: % of treated VS % of untreated
    },

    AverageBleedsPerPatientsWithProphylaxis() {
          if (this.$store.state.dashboardData == null) return 0;
          return (this.$store.state.dashboardData["averageBleedsPerPatientsWithProphylaxis"]);
    },

    AverageBleedsPerPatientsWithoutProphylaxis() {
          if (this.$store.state.dashboardData == null) return 0;
          return (this.$store.state.dashboardData["averageBleedsPerPatientsWithoutProphylaxis"])
          
    },
    ///////////////////////







    filters() {
      return this.$store.getters.filters;
    },
    filtersRegion() {
      return this.filters.region;
    },
    filtersAge() {
      return this.filters.ageGroup;
    },
    filtersGni() {
      return this.filters.gni;
    },
    filtersRegistrationYears() {
      return this.filters.dateOfRegistration;
    },
  },
  watch: {
    averageBleed(val) {
      if (val != null) this.loading = false;
    },
    AverageBleedsPerPatientsWithProphylaxis(val) {
      if (val != null) this.loading = false;
    },
    AverageBleedsPerPatientsWithoutProphylaxis(val) {
      if (val != null) this.loading = false;
    },
  },
  
};
</script>


<style scoped>
.md_item {
  font-weight: 300;
  font-size: 0.85em;
}

.md_span {
  padding-right: 16px;
}

.lil_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
</style>
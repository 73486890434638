<template>
  <v-card>
    <v-row>
      <v-col cols="11">
        <v-select
          :items="regionOptions"
          label="Region "
          solo
          v-model="regionFilter"
          height="30"
          hide-details=""
        ></v-select
      ></v-col>
      <v-col
        ><button class="reset_btn" @click="regionFilter = null">
          clear
        </button></v-col
      >
    </v-row>

    <v-row>
      <v-col cols="11">
        <v-select
          :items="gniOptions"
          item-text="text"
          item-value="value"
          label="GNI "
          dense
          solo
          v-model="gniFilter"
          height="30"
          hide-details=""
        ></v-select
      ></v-col>
      <v-col
        ><button class="reset_btn" @click="gniFilter = null">
          clear
        </button></v-col
      >
    </v-row>

    <v-row>
      <v-col cols="11">
        <v-select
          :items="severityOptions"
          label="Severity "
          dense
          solo
          v-model="severityFilter"
          height="30"
          hide-details=""
        ></v-select
      ></v-col>
      <v-col
        ><button class="reset_btn" @click="severityFilter = null">
          clear
        </button></v-col
      >
    </v-row>

    <v-row>
      <v-col cols="11">
        <v-select
          :items="calculatedYears"
          label="Registration date "
          dense
          solo
          v-model="yearFilter"
          height="30"
          hide-details=""
        ></v-select
      ></v-col>
      <v-col
        ><button class="reset_btn" @click="yearFilter = null">
          clear
        </button></v-col
      >
    </v-row>

    <v-row v-if="isUserLoggedIn">
      <v-col cols="11">
        <v-select
          :items="htcs"
          item-text="name"
          item-value="id"
          label="HTC "
          dense
          solo
          v-model="htcFilter"
          height="30"
          hide-details=""
        ></v-select
      ></v-col>
      <v-col
        ><button class="reset_btn" @click="htcFilter = null">
          clear
        </button></v-col
      >
    </v-row>

    <v-divider></v-divider>
    <v-list dense>
      <v-list-item>
        <v-list-item-content>Age at first diagnosis:</v-list-item-content>
        <v-list-item-content class="align-end">
          {{ data.row1 }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>Age at first bleed:</v-list-item-content>
        <v-list-item-content class="align-end">
          {{ data.row2 }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>Age at first joint bleed:</v-list-item-content>
        <v-list-item-content class="align-end">
          {{ data.row3 }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>Pediatric % VS Adult %:</v-list-item-content>
        <v-list-item-content class="align-end">
          {{ data.row4 }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>% of patients of prophylaxis:</v-list-item-content>
        <v-list-item-content class="align-end">
          {{ data.row5 }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>Annualized bleeding rate:</v-list-item-content>
        <v-list-item-content class="align-end">
          {{ data.row6 }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content
          >Annualized joint bleeding rate:</v-list-item-content
        >
        <v-list-item-content class="align-end">
          {{ data.row66 }}
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <hr class="hr-text" data-content="Overall Stats" />
    <!-- truc de sass que j'ai mis en scoped à la fin du fichier -->

    <v-list dense>
      <v-list-item>
        <v-list-item-content>Number of hospitalization:</v-list-item-content>
        <v-list-item-content class="align-end">
          {{ data.row7 }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content
          >Percentage of unique hospitalized patients:</v-list-item-content
        >
        <v-list-item-content class="align-end">
          {{ data.row8 }}
        </v-list-item-content>
      </v-list-item>
      <hr class="hr-text" data-content="Hospitalization reasons" />
      <!-- truc de sass que j'ai mis en scoped à la fin du fichier -->
      <v-list-item>
        <v-list-item-content>Intracranial bleeding</v-list-item-content>
        <v-list-item-content class="align-end">
          {{ data.row9 }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>Joint bleeding:</v-list-item-content>
        <v-list-item-content class="align-end">
          {{ data.row10 }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content
          >Non-hemophilia related hospitalization</v-list-item-content
        >
        <v-list-item-content class="align-end">
          {{ data.row11 }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>Other</v-list-item-content>
        <v-list-item-content class="align-end">
          {{ data.row12 }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>Other bleeding</v-list-item-content>
        <v-list-item-content class="align-end">
          {{ data.row13 }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>Other muscle bleeding</v-list-item-content>
        <v-list-item-content class="align-end">
          {{ data.row14 }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>Psoas muscle bleeding</v-list-item-content>
        <v-list-item-content class="align-end">
          {{ data.row15 }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>Soft tissue bleeding</v-list-item-content>
        <v-list-item-content class="align-end">
          {{ data.row16 }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>Surgery</v-list-item-content>
        <v-list-item-content class="align-end">
          {{ data.row17 }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>Thromboembolic event</v-list-item-content>
        <v-list-item-content class="align-end">
          {{ data.row18 }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import axios from "axios";

function getSafe(fn, defaultVal) {
  try {
    return fn();
  } catch (e) {
    return defaultVal;
  }
}

export default {
  props: ["title", "abbr"],
  // components: {
  //   htcCompareView,
  // },
  data() {
    return {
      regionFilter: null,
      gniFilter: null,
      severityFilter: null,
      yearFilter: null,
      htcFilter: null,
      htcs: [],
      reasons: null,
      reasonsAreLoaded: false, // in 'created', wait till loadHtcs is completed before rendering in the template
      compareData: {},
      regionOptions: [
        "Africa",
        "Americas",
        "Eastern Mediterranean",
        "Europe",
        "South East Asia",
        "Western Pacific",
      ],
      gniOptions: [
        {
          text: "High",
          value: "H",
        },
        {
          text: "Upper middle",
          value: "UM",
        },
        {
          text: "Lower middle",
          value: "LM",
        },
        {
          text: "Low",
          value: "L",
        },
      ],

      severityOptions: ["Severe", "Moderate", "Mild"],
      data: {},
    };
  },

  computed: {
    isUserLoggedIn() {
      return this.$store.state.currentUser.jwt;
    },
    isAdmin() {
      return this.$store.state.currentUser.role == "Admin";
    },
    isWFH() {
      return this.$store.state.currentUser.role == "WFH";
    },
    pediatricPatientPercentage() {
      return getSafe(
        () => this.compareData.pediatricVsAdultStats.pediatricPatientPercentage
      );
    },
    adultPatientPercentage() {
      return getSafe(
        () => this.compareData.pediatricVsAdultStats.adultPatientPercentage
      );
    },
    patientWithProphylaxisPercentage() {
      return getSafe(
        () => this.compareData.prophylaxisStats.patientWithProphylaxisPercentage
      );
    },

    // compareData() {
    //   // if (this.$store.state.compareData == null) return null;
    //   var data = this.$store.state.compareData;

    //   return data;
    // },

    calculatedYears() {
      // Cette fonction sert seulement à remplir les années dans le dropdown
      const cetteAnnee = new Date().getFullYear();
      const debut = 2018;
      const annees = [];

      for (let i = cetteAnnee; i >= debut; i--) {
        annees.push(i);
      }
      return annees;
    },
  },

  // filters() {
  //   return this.$store.state.filters;
  // },

  methods: {
    resetAllFilters() {
      this.regionFilter = null;
      this.gniFilter = null;
      this.severityFilter = null;
      this.yearFilter = null;
      this.htcFilter = null;
      this.data = {};
    },
    loadHTCs() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_URI}/htc`)
        .then((response) => {
          this.htcs = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadData() {
      this.$store.commit("toggleIsDashboardLoading", true);
      var data = {
        Region: this.regionFilter,
        GNI: this.gniFilter,
        Severity: this.severityFilter,
        Year: this.yearFilter,
        HtcId: this.htcFilter,
      };
      var xhr = axios.post(
        `${process.env.VUE_APP_BACKEND_URI}/Stats/Compare`,
        data
      );

      xhr.then((res) => {
        this.compareData = res.data;
        this.updateData();
        this.$store.commit("toggleIsDashboardLoading", false);
      });
    },
    loadReasons() {
      this.reasons =
        this.$store.state.compareData.htcStats.patientCountByHopitalizationReasons;
      this.reasonsAreLoaded = true;
    },
    updateData() {
      this.data = {
        name: this.title,
        row1: Math.round(parseFloat(this.compareData.medianAgeAtDiagnosis)),
        row2: Math.round(parseFloat(this.compareData.medianAgeAtFirstBleed)),
        row3: Math.round(
          parseFloat(this.compareData.medianAgeAtFirstJointBleed)
        ),
        row4:
          Math.round(parseFloat(this.pediatricPatientPercentage)) +
          " VS " +
          Math.round(parseFloat(this.adultPatientPercentage)),
        row5: Math.round(parseFloat(this.patientWithProphylaxisPercentage)),
        row6: Math.round(parseFloat(this.compareData.annualizedBleedingRate)),
        row66: Math.round(
          parseFloat(this.compareData.annualizedJointBleedingRate)
        ),
        row7: Math.round(
          parseFloat(this.compareData.htcStats.numberOfHospitalization)
        ),
        row8: Math.round(
          parseFloat(this.compareData.htcStats.percentageOfPatientsHospitalized)
        ),
        row9: Math.round(
          parseFloat(
            this.compareData.htcStats.patientCountByHopitalizationReasons[0]
              .patientCount
          )
        ),
        row10: Math.round(
          parseFloat(
            this.compareData.htcStats.patientCountByHopitalizationReasons[1]
              .patientCount
          )
        ),
        row11: Math.round(
          parseFloat(
            this.compareData.htcStats.patientCountByHopitalizationReasons[2]
              .patientCount
          )
        ),
        row12: Math.round(
          parseFloat(
            this.compareData.htcStats.patientCountByHopitalizationReasons[3]
              .patientCount
          )
        ),
        row13: Math.round(
          parseFloat(
            this.compareData.htcStats.patientCountByHopitalizationReasons[4]
              .patientCount
          )
        ),
        row14: Math.round(
          parseFloat(
            this.compareData.htcStats.patientCountByHopitalizationReasons[5]
              .patientCount
          )
        ),
        row15: Math.round(
          parseFloat(
            this.compareData.htcStats.patientCountByHopitalizationReasons[6]
              .patientCount
          )
        ),
        row16: Math.round(
          parseFloat(
            this.compareData.htcStats.patientCountByHopitalizationReasons[7]
              .patientCount
          )
        ),
        row17: Math.round(
          parseFloat(
            this.compareData.htcStats.patientCountByHopitalizationReasons[8]
              .patientCount
          )
        ),
        row18: Math.round(
          parseFloat(
            this.compareData.htcStats.patientCountByHopitalizationReasons[9]
              .patientCount
          )
        ),
      };
    },
  },

  watch: {
    regionFilter() {
      this.loadData();
    },
    gniFilter() {
      this.loadData();
    },
    severityFilter() {
      this.loadData();
    },
    yearFilter() {
      this.loadData();
    },

    htcFilter() {
      this.loadData();
    },
  },
  created() {
    this.loadHTCs();
    this.loadData();
  },
};
</script>

<style scoped>
.md_col {
  padding: 10px;

  background-color: white;
  border-radius: 8px;
  box-shadow: 4px 4px 10px #ccc;
}
.v-list-item {
  border-bottom: 1px dashed #ccc;
}
hr {
  margin: 40px;
}
.mdbtn {
  background-color: #123175;
  color: white;
  padding: 8px 25px;
  border-radius: 2px;
  text-decoration: none;
  margin: 15px 0;
}

.responstable {
  margin: 1em 0;
  width: 100%;
  overflow: hidden;
  background: #fff;
  color: #024457;
  border-radius: 10px;
  border: 1px solid #167f92;
}
.responstable th {
  max-width: 10px;
  font-weight: 300;
}
.responstable tr {
  border: 1px solid #d9e4e6;
}
.responstable tr:nth-child(odd) {
  background-color: #eaf3f3;
}
.responstable th {
  display: none;
  border: 1px solid #fff;
  background-color: #123175;
  color: #fff;
  padding: 1em;
}
.responstable th:first-child {
  display: table-cell;
  text-align: center;
}
.responstable th:nth-child(2) {
  display: table-cell;
}
.responstable th:nth-child(2) span {
  display: none;
}
.responstable th:nth-child(2):after {
  content: attr(data-th);
}
@media (min-width: 480px) {
  .responstable th:nth-child(2) span {
    display: block;
  }
  .responstable th:nth-child(2):after {
    display: none;
  }
}
.responstable td {
  display: block;
  word-wrap: break-word;
  max-width: 7em;
}
.responstable td:first-child {
  display: table-cell;
  text-align: center;
  border-right: 1px solid #d9e4e6;
}
@media (min-width: 480px) {
  .responstable td {
    border: 1px solid #d9e4e6;
  }
}
.responstable th,
.responstable td {
  text-align: left;
  margin: 0.5em 1em;
}
@media (min-width: 480px) {
  .responstable th,
  .responstable td {
    display: table-cell;
    padding: 1em;
  }
}

/* Style pour les dropdowns */
/* .box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.box select {
  border: 1px solid #123175;
  color: #123175;
  font-size: 0.9em;
  font-family: sans-serif;
  padding: 8px;
  width: 250px;

  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  -webkit-appearance: button;
  appearance: button;
}

/* .box::before {
  content: "\f13a";
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  right: 0;
  width: 20%;
  height: 100%;
  text-align: center;
  
  line-height: 45px;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.box:hover::before {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
} */

.box select option {
  padding: 30px;
}

.md_clear {
  /* background-color:#0067ab; */
  /* background-color:#123175; */
  background-color: #009fdb;

  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 10px;
  color: white;
  margin-left: 8px;
  box-shadow: 3px 6px 10px #ddd;
}

.md_clear:hover {
  color: black;
}

.v-card {
  box-shadow: 0px 0px 8px #ccc !important;
  border: 1px solid #eee;
  padding: 16px;
}

.v-card__title {
  background-color: rgb(236, 236, 236);
}

.v-input--switch {
  display: none;
}

.md_wrapper {
  padding: 0 10%;
}
</style>



<style lang="scss" scoped>
.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
  &:before {
    content: "";
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 0.5em;
    line-height: 1.5em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    color: #818078;
    background-color: #fcfcfa;
  }
}

.filter_row {
  //padding:0;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

.reset_btn {
  // background-color: #c4c4c4;
  color: rgb(124, 122, 122);
  font-family: sans-serif;
  font-size: 0.75em;
  margin: 10px 0 0 -15px;
  // width: 30px;
  // height: 30px;
  // border-radius: 50%;
  // box-shadow: 0 0 8px #ccc;
}

.reset_btn:hover {
  // background-color: rgb(155, 155, 155);
  color: black;
}
</style>
  

<template>
  <div class="md_center">
    <img src="/assets/img/Blood-drop.jpg" />
    <div class="flex-col">
      <p class="pt-0 ma-0">{{ $t("widgets.annualBleedRate.allBleeds") }}</p>
      <h3>{{ bleedCount }}</h3>
    </div>
  </div>


  <!-- <base-widget
    :titleUnder="true"
    :md="12"
    :sm="4"
    imageName="/assets/img/Blood-drop.jpg"
    :imageWidth="30"
    :isDense="true"
  >
    <template slot="title"><p>All bleeds</p></template>

    <template slot="text">
      <h1 v-counter="bleedCount" class="text-center">0</h1>
    </template></base-widget
  > -->
</template>
<script>
import BaseWidget from "./BaseWidget";
export default {
  components: { 
    //baseWidget: BaseWidget 
    },  data() {
    return {
      bleedCountStats: 0,
      loading: true,
    };
  },
  computed: {
    bleedCount() {
      if (this.$store.state.dashboardData == null) return 0;
      return this.$store.state.dashboardData["annualBleedRate"];
    },
    filters() {
      return this.$store.getters.filters;
    },
    filtersRegion() {
      return this.filters.region;
    },
    filtersAge() {
      return this.filters.ageGroup;
    },
    filtersGni() {
      return this.filters.gni;
    },
    filtersRegistrationYears() {
      return this.filters.dateOfRegistration;
    },
  },
  watch: {
    bleedCount(val) {
      if (val != null) this.loading = false;
    },
  },
};
</script>

<style scoped>
.md_center img {
  max-width: 30px;
}
.md_center h3 {
  color: #666;
}
.md_center {
  display: flex;
  //justify-content: center;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
  padding-left: 1em;
}
</style>
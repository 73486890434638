<template>
  <base-widget
    :titleUnder="true"
    :md="12"
    :sm="4"
    imageName="/assets/img/Blood-drop.jpg"
    :imageWidth="40"
  >
    <template slot="title">{{
      $t("widgets.numberOfPatientsWithoutBleedWidget.title")
    }}</template>
    <template slot="text">
      <h1 v-counter="bleedCount" class="text-center">0</h1>
    </template></base-widget
  >
</template>
<script>
import gql from "graphql-tag";
import BaseWidget from "./BaseWidget";
export default {
  components: { baseWidget: BaseWidget },
  data() {
    return {
      bleedCountStats: 0,
      loading: true,
    };
  },
  computed: {
    bleedCount() {
      if (this.$store.state.dashboardData == null) return 0;
      return this.$store.state.dashboardData["numberOfPatientsWithNoBleed"];
    },
    filters() {
      return this.$store.getters.filters;
    },
    filtersRegion() {
      return this.filters.region;
    },
    filtersAge() {
      return this.filters.ageGroup;
    },
    filtersGni() {
      return this.filters.gni;
    },
    filtersRegistrationYears() {
      return this.filters.dateOfRegistration;
    },
  },
  watch: {
    bleedCount(val) {
      if (val != null) this.loading = false;
    },
  },
  // apollo: {
  //   bleedCountStats: {
  //     query: gql`
  //       query BleedCountStats(
  //         $regions: [String]
  //         $minAge: Int
  //         $maxAge: Int
  //         $gni: [String]
  //         $hemoTypes: [String]
  //         $minRegistrationYear: Int
  //         $maxRegistrationYear: Int
  //       ) {
  //         numberOfPatientsWithNoBleed(
  //           regions: $regions
  //           ageGroup: { min: $minAge, max: $maxAge }
  //           gni: $gni
  //           hemophiliaTypes: $hemoTypes
  //           registrationYear: {
  //             min: $minRegistrationYear
  //             max: $maxRegistrationYear
  //           }
  //         )
  //       }
  //     `,
  //     variables() {
  //       var filters = this.filters;
  //       var v = {
  //         regions: filters.region,
  //         minAge: this.filtersAge.min,
  //         maxAge: this.filtersAge.max,
  //         age: this.filtersAge,
  //         gni: filters.gni,
  //         hemoTypes: filters.hemophiliaType,
  //         minRegistrationYear: this.filtersRegistrationYears.min,
  //         maxRegistrationYear: this.filtersRegistrationYears.max,
  //       };
  //       return v;
  //     },
  //     update: function (data) {
  //       return data.numberOfPatientsWithNoBleed;
  //     },
  //     watchLoading({ loading, etc }) {
  //       this.loading = loading;
  //     },
  //   },
  // },
  created() {
    this.loading = true;
  },
  updated() {
    this.loading = false;
  },
};
</script>
<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="filter-header">{{
      this.$t("filters.dateOfRegistration.title")
    }}</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-range-slider
        v-model="dateOfRegistration"
        :value="dateOfRegistration"
        min="2018"
        :max="new Date().getFullYear()"
        :hint="$t('filters.dateOfRegistration.hint')"
        ticks="always"
        :thumb-label="true"
        @end="filterDateOfRegistration($event)"
        persistent-hint
        tick-size="4"
        :tick-labels="[]"
        color="#0082ed"
      >
        <template v-slot:append>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">mdi-help-circle-outline</v-icon> </template
            >{{ $t("filters.dateOfRegistration.tooltip") }}</v-tooltip
          ></template
        ></v-range-slider
      >
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
export default {
  data() {
    return {
      filterTimeout: null,
      dateOfRegistration: [2018, new Date().getFullYear()],
    };
  },
  computed: {
    filter() {
      return this.$store.state.filters.dateOfRegistration;
    },
  },
  watch: {
    // dateOfRegistration: function () {
    //   if (this.updateTimeout) clearTimeout(this.updateTimeout);
    //   this.updateTimeout = setTimeout(() => {
    //     var filters = this.$store.state.filters;
    //     filters.dateOfRegistration = this.dateOfRegistration;
    //     this.$store.commit("setFilters", filters);
    //   }, 300);
    // },
    filter(o) {
      if (o == null) {
        this.dateOfRegistration = [2018, new Date().getFullYear()];
      }
    },
  },
  methods: {
    applyFilter(dates) {
      if (this.filterTimeout != null) clearTimeout(this.filterTimeout);
      this.filterTimeout = setTimeout(() => {
        var filters = this.$store.state.filters;
        filters.dateOfRegistration = dates;
        this.$store.commit("setFilters", filters);
      }, 300);
    },
    filterDateOfRegistration(val) {
      var dor = { min: val[0], max: val[1] };
      this.applyFilter(dor);
    },
  },
  created() {},
};
</script>
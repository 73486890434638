<template>
  <div class="md_center">
    <img src="/assets/img/Target-joint_2.jpg" />
    <div class="flex-col">
      <p class="pt-0 ma-0">{{ $t("widgets.annualBleedRate.jointBleeds") }}</p>
      <h3>        
        <i18n-n
          v-counter="medianAgeAtFirstJointBleedData"
          :value="medianAgeAtFirstJointBleedData"
          >0.0</i18n-n
        >
        {{ $t("widgets.medianJointBleedAge.yearsOld") }}
      </h3>
    </div>
  </div>
  <!-- <base-widget
    :titleUnder="true"
    :md="12"
    :sm="4"
    imageName="/assets/img/Target-joint_2.jpg"
    :imageWidth="25"
    :isDense="true"
  >
    <template slot="title">Joint bleeds</template>

    <template slot="text"
      ><h2 class="text-center">
        <i18n-n
          v-counter="medianAgeAtFirstJointBleedData"
          :value="medianAgeAtFirstJointBleedData"
          >0.0</i18n-n
        >
        {{ $t("widgets.medianJointBleedAge.yearsOld") }}
      </h2></template>
  </base-widget> -->
</template>

<script>
import BaseWidget from "./BaseWidget";
import gql from "graphql-tag";
export default {
  components: { 
    //baseWidget: BaseWidget 
    },
  data() {
    return {
      medianAgeAtFirstJointBleed: "0.0",
    };
  },
  computed: {
    medianAgeAtFirstJointBleedData() {
      if (this.$store.state.dashboardData == null) return 0;
      return Math.round(
        this.$store.state.dashboardData["medianAgeAtFirstJointBleed"]
      );
    },
    filters() {
      return this.$store.getters.filters;
    },
    filtersRegion() {
      return this.filters.region;
    },
    filtersAge() {
      return this.filters.ageGroup;
    },
    filtersGni() {
      return this.filters.gni;
    },
    filtersRegistrationYears() {
      return this.filters.dateOfRegistration;
    },
  },
  watch: {
    medianAgeAtFirstJointBleedData(val) {
      if (val != null) this.loading = false;
    },
  },
  // apollo: {
  //   medianAgeAtFirstJointBleed: {
  //     query() {
  //       return gql`
  //         query MedianAgeAtFirstJointBleed(
  //           $regions: [String]
  //           $minAge: Int
  //           $maxAge: Int
  //           $gni: [String]
  //           $hemoTypes: [String]
  //           $minRegistrationYear: Int
  //           $maxRegistrationYear: Int
  //         ) {
  //           medianAgeAtFirstJointBleed(
  //             regions: $regions
  //             ageGroup: { min: $minAge, max: $maxAge }
  //             gni: $gni
  //             hemophiliaTypes: $hemoTypes
  //             registrationYear: {
  //               min: $minRegistrationYear
  //               max: $maxRegistrationYear
  //             }
  //           )
  //         }
  //       `;
  //     },
  //     variables() {
  //       var filters = this.filters;
  //       var v = {
  //         regions: filters.region,
  //         minAge: this.filtersAge.min,
  //         maxAge: this.filtersAge.max,
  //         age: this.filtersAge,
  //         gni: filters.gni,
  //         hemoTypes: filters.hemophiliaType,
  //         minRegistrationYear: this.filtersRegistrationYears.min,
  //         maxRegistrationYear: this.filtersRegistrationYears.max,
  //       };
  //       return v;
  //     },
  //     update: function (data) {
  //       return data.medianAgeAtFirstJointBleed;
  //     },
  //     watchLoading({ loading, etc }) {
  //       this.loading = loading;
  //     },
  //   },
  // },
  created() {
    this.loading = true;
  },
  updated() {
    this.loading = false;
  },
};
</script>

<style scoped>
.md_center img {
  margin-left: 0.5em;
  max-width: 20px;
}
.md_center h3 {
  color: #666;
}
.md_center {
  display: flex;
  //justify-content: center;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
  padding-left: 1em;
}
</style>
import Vue from 'vue'
import i18n from '../i18n';

Vue.directive('counter', {
    update: function (element, binding) {
        const updateCount = () => {
            const speed = 200;
            const count = +element.innerText;
            const inc = binding.value / speed;

            if (count < binding.value) {
                element.innerText = i18n.n(Math.ceil(count + inc));
                setTimeout(updateCount, 1);
            } else {

                element.innerText = i18n.n(Math.ceil(binding.value));
            }
        }
        updateCount();
    }
})
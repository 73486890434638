<template>
  <v-dialog
    v-model="showDialog"
    scrollable
    persistent
    no-click-animation
    max-width="30%"
  >
    <component :is="dialogName" :parameter="parameter"></component>
    <!-- <v-card>
      <v-card-title></v-card-title>
      <v-card-title class="d-flex justify-end pa-3" style="background: #616265">
        <v-btn fab small dark color="#9d9fa2" @click="showDialog = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
      </v-card-text>
    </v-card> -->
  </v-dialog>
</template>
<script>
export default {
  name: "dialog-handler",
  computed: {
    showDialog: {
      get() {
        return this.$store.state.modals.length > 0;
      },
      set(v) {
        if (v == true) this.$store.commit("showModal", null);
        else this.$store.commit("hideModal", null);
      },
    },
    dialogName() {
      if (this.$store.state.modals.length > 0)
        return this.$store.state.modals[this.$store.state.modals.length - 1]
          .component;
      return "";
    },
    parameter() {
      if (this.$store.state.modals.length > 0)
        return this.$store.state.modals[this.$store.state.modals.length - 1]
          .parameter;
      return "";
    },
  },
};
</script>
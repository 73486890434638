import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../components/dashboard.vue'
import Users from '../components/Users.vue'
import Filters from '../components/Filters.vue'
import store from '../store/index';
import ResetPassword from '../components/ResetPassword'
import CompareView from '../components/CompareView';

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Dashboard',
    components: {
        default: Dashboard,
        filters: Filters
    }
},
{
    path: '/resetpassword/:enc',
    name: 'resetpassword',
    components: {
        default: ResetPassword,
    },
    props: {
        default: true,
        navigation: route => ({ search: route.query.q })
    }
},
{
    path: '/users',
    name: 'Users',
    components: {
        default: Users
    },
    meta: {
        requiresAuth: true,
        role: 'WFH'
    }
},

{
    path: '/compare',
    name: 'Compare',
    components: {
        default: CompareView
    }
}

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
<template>
  <v-col :md="colMd" :sm="colSm">
    <v-card elevation="0" height="90%" max-width="100%" :class="isDense ? 'pa-0' : ''">
      <template slot="progress">
        <v-progress-linear color="#0082ed" indeterminate></v-progress-linear>
      </template>


      
      <v-card elevation="0" width="100%" max-width="100%" :class="isDense ? 'pa-0' : ''">
        <v-card-text v-if="isTitleUnder" :class="isDense ? 'pa-0' : ''">
          <v-row :class="isDense ? 'pa-0' : ''">
            <v-col v-if="imageName" class="mdcol" xs="3" sm="3" md="3" lg="3" xl="3">
              <v-img :width="imageWidth" :min-width="imageMinWidth" :max-width="imageMaxWidth" :src="imageName" class="md_img"></v-img>
            </v-col>
            <v-col v-if="imageName" class="mdcol" xs="9" sm="9" md="9" lg="9" xl="9">
              <div class="mdtitle"><v-card-text><slot name="title"></slot></v-card-text></div>
              <v-row>
                <v-col xs="6" sm="12" md="12" lg="12" xl="12" class="mdcol md_data">
                  <div class="mdinfo"><v-card-title><slot name="text"></slot></v-card-title></div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <v-card-text ><slot name="title"></slot></v-card-text>
          <v-card-text><slot name="text"></slot></v-card-text>
        </v-card-text>
      </v-card>
    </v-card>
  </v-col>
</template>





<script>
export default {
  props: {
    md: Number,
    sm: Number,
    classes: String,
    titleClass: String,
    type: String,
    titleUnder: Boolean,
    imageName: String,
    imageWidth: Number,
    imageMaxWidth: Number,
    imageMinWidth: Number,
    isDense : Boolean
  },
  computed: {
    isCounter() {
      return this.type == "counter" || this.type == null;
    },
    isGraph() {
      return this.type == "graph";
    },
    isMap() {
      return this.type == "map";
    },
    isLoading() {
      return this.loading;
    },
    isTitleUnder() {
      return this.titleUnder;
    },
    classesType() {
      if (this.classes) return this.classes;
      else if (this.isCounter) return "d-flex flex-column";
      else return "";
    },
    titleClasses() {
      if (this.titleClass) return this.titleClass;
      return "";
    },
    colMd() {
      if (this.md) return this.md;
      else if (this.isMap) return 9;
      else if (this.isGraph) return 4;
      else if (this.isCounter) return 2;
      return 4;
    },
    colSm() {
      if (this.md) return this.md;
      else if (this.isMap) return 12;
      else if (this.isGraph) return 12;
      return 6;
    },
    widgetHeight() {
      if (this.isCounter) return "50%";
      return "100%";
    },
    widgetTitleHeight() {
      if (this.isCounter) return "50%";
      return "auto";
    },
  },
};
</script> 


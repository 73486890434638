<template>
  <base-widget :loading="loading" type="graph" :md="12" :sm="4">
    <template slot="title">{{
      $t("widgets.patientsWithProphylaxisRatioWidget.title")
    }}</template>
    <template slot="text">
      <v-slide-y-transition>
        <GChart
          class="pa-15"
          v-if="!loading"
          :setting="{ packages: ['corechart', 'bar'] }"
          type="PieChart"
          :data="chartData"
          :options="{
            animation: {
              duration: 1500,
              easing: 'out',
              startup: true,
            },
            legend: {
              position: 'bottom',
              textStyle: {
                fontSize: `${legendFontSize}`,
              },
            },
            chartArea: {
              width: '100%',
              height: '80%',
            },
            width: '500',
            height: '280',
            colors: ['#8c1f70', '#d6d8d9', '#b91b66', '#123175'],
          }"
        ></GChart>
      </v-slide-y-transition>
    </template>
  </base-widget>
</template>
<script>
import BaseWidget from "./BaseWidget";
import gql from "graphql-tag";
export default {
  components: { baseWidget: BaseWidget },
  data() {
    return {
      patientsThatHadProphylaxisVsNeverHad: [],
      loading: false,
    };
  },
  computed: {
    chartData() {
      if (this.$store.state.dashboardData == null) return null;
      var data = this.$store.state.dashboardData["prophylaxisRatio"];
      var chart = [
        [
          this.$t("widgets.patientsWithProphylaxisRatioWidget.prophylaxis"),
          this.$t("widgets.patientsWithProphylaxisRatioWidget.never"),
          //{ role: "annotation" },
        ],
      ];

      chart.push([
        this.$t("widgets.patientsWithProphylaxisRatioWidget.prophylaxis"),
        data.patientWithProphylaxis,
      ]);

      chart.push([
        this.$t("widgets.patientsWithProphylaxisRatioWidget.never"),
        data.patientWithoutProphylaxis,
      ]);

      return chart;
    },
    legendFontSize() {
      if (this.$vuetify.breakpoint.name == "xl") return 16;
      return 14;
    },
  },
  watch: {},
  // apollo: {
  //   patientsThatHadProphylaxisVsNeverHad: {
  //     query() {
  //       return gql`
  //         query PatientsThatHadProphylaxisVsNeverHad(
  //           $regions: [String]
  //           $minAge: Int
  //           $maxAge: Int
  //           $gni: [String]
  //           $hemoTypes: [String]
  //           $minRegistrationYear: Int
  //           $maxRegistrationYear: Int
  //         ) {
  //           patientsThatHadProphylaxisVsNeverHad(
  //             regions: $regions
  //             ageGroup: { min: $minAge, max: $maxAge }
  //             gni: $gni
  //             hemophiliaTypes: $hemoTypes
  //             registrationYear: {
  //               min: $minRegistrationYear
  //               max: $maxRegistrationYear
  //             }
  //           ) {
  //             count
  //             treatmentType
  //           }
  //         }
  //       `;
  //     },
  //     variables() {
  //       var filters = this.$store.getters.filters;
  //       var v = {
  //         regions: filters.region,
  //         minAge: filters.ageGroup.min,
  //         maxAge: filters.ageGroup.max,
  //         gni: filters.gni,
  //         hemoTypes: filters.hemophiliaType,
  //         minRegistrationYear: filters.dateOfRegistration.min,
  //         maxRegistrationYear: filters.dateOfRegistration.max,
  //       };
  //       return v;
  //     },
  //     watchLoading({ loading, etc }) {
  //       this.loading = loading;
  //     },
  //   },
  // },
};
</script>
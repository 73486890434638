<template>
  <v-snackbar absolute :bottom="true" v-model="showSnackbar" :timeout="timeout">
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn color="blue" text v-bind="attrs" @click="showSnackbar = false">
        {{ $t("app.close") }}
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      snackbar: false,
    };
  },
  computed: {
    timeout() {
      return 5000;
    },
    text() {
      return "My timeout is set to 2000.";
    },
    showSnackbar: {
      get() {
        return this.snackbar;
      },
      set(val) {
        this.snackbar = val;
      },
    },
  },
  watch: {},
};
</script>
<template>
  <base-widget :loading="loading" :titleUnder="true">
    <template slot="title">{{ $t("widgets.inhibitorsCount.title") }}</template>

    <template slot="text">
      <h1 v-counter="inhibitorCount" class="text-center">0</h1>
    </template>
  </base-widget>
</template>

<script>
import gql from "graphql-tag";
import BaseWidget from "./BaseWidget.vue";
export default {
  components: { baseWidget: BaseWidget },
  data() {
    return {
      inhibitorsStats: 0,
      loading: true,
    };
  },
  computed: {
    inhibitorCount() {
      if (this.$store.state.dashboardData == null) return 0;
      return this.$store.state.dashboardData["totalInhibitorTestCount"];
    },
    filters() {
      return this.$store.getters.filters;
    },
    filtersRegion() {
      return this.filters.region;
    },
    filtersAge() {
      return this.filters.ageGroup;
    },
    filtersGni() {
      return this.filters.gni;
    },
    filtersRegistrationYears() {
      return this.filters.dateOfRegistration;
    },
  },
  watch: {
    inhibitorCount(val) {
      if (val != null) this.loading = false;
    },
  },
};
</script>
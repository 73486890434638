<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="filter-header">{{
      this.$t("filters.htcId.title")
    }}</v-expansion-panel-header>
    <v-expansion-panel-content class="py-0">
      <v-combobox
        v-model="selectedHtc"
        :items="nmos"
        item-value="id"
        item-text="name"
        @change="htcFilter($event)"
        clearable
        :search-input.sync="searchInput"
        color="#0082ed"
      >
        <template v-slot:item="{ item }">
          <v-list>
            <v-list-item-title class="mb-2">{{ item.name }}</v-list-item-title
            ><v-spacer></v-spacer>
            <v-list-item-subtitle>{{ item.nmo }}</v-list-item-subtitle>
          </v-list>
        </template>
        <!-- <template v-slot:selection="{ item }">
          <span class="text-truncate">{{ item.name }}</span>
        </template> -->
      </v-combobox>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      nmos: [],
      selectedHtc: null,
      searchInput: null,
    };
  },
  computed: {
    isHTC() {
      return this.$store.state.currentUser.role == "HTC";
    },
    filters() {
      return this.$store.state.filters;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler(o) {
        if (o["htcId"] == null) {
          this.selectedHtc = null;
          this.searchInput = null;
        }
      },
    },
  },
  methods: {
    applyFilter(htc) {
      var filters = this.$store.state.filters;
      filters.htcId = htc?.id;
      filters.htcName = htc?.name;

      this.$store.commit("setFilters", filters);
    },
    htcFilter(htc) {
      this.applyFilter(htc);
    },
    loadHTCs() {
      axios.get(`${process.env.VUE_APP_BACKEND_URI}/htc`).then((response) => {
        this.nmos = response.data;
      });
    },
  },
  created() {
    this.loadHTCs();
  },
};
</script>
<template>
  <base-widget :loading="loading" type="graph" :md="12"
    :sm="4">

    <template slot="title">{{ $t("widgets.hivStatusWidget.title") }}</template>
    <template slot="text">
      <v-switch v-model="chartSwitch" color="accent"></v-switch>
      <v-slide-y-transition>
        <GChart
          v-if="chartSwitch && !loading"
          :setting="{ packages: ['corechart', 'bar'] }"
          type="PieChart"
          :data="comorbidityData"
          :options="{
            animation: {
              duration: 1500,
              easing: 'out',
              startup: true,
            },
            legend: {
              position: 'right',
              maxLines: '11',
            },
            width: 'auto',
            height: '500',
            colors: ['#e31837', '#616265', '#9d9fa2'],
          }"
        ></GChart>
      </v-slide-y-transition>
      <v-slide-y-transition>
        <GChart
          v-if="!chartSwitch && !loading"
          :setting="{ packages: ['corechart'] }"
          type="ColumnChart"
          :data="comorbidityData"
          :options="{
            animation: {
              duration: 1500,
              easing: 'out',
              startup: true,
            },
            legend: {
              position: 'none',
            },
            width: 'auto',
            height: '500',
            colors: ['#e31837', '#616265', '#9d9fa2'],
            vAxis: {
              title: numberOfPatients,
              titleTextStyle: {
                bold: true,
                italic: false,
              },
            },
            hAxis: {
              title: hivStatus,
              titleTextStyle: {
                bold: true,
                italic: false,
              },
            },
          }"
        ></GChart>
      </v-slide-y-transition>
    </template>
  </base-widget>
</template>
<script>
import gql from "graphql-tag";
import BaseWidget from "./BaseWidget";
export default {
  components: {
    baseWidget: BaseWidget,
  },
  data() {
    return {
      loading: false,
      comorbidityStats: [],
      chartSwitch: true,
    };
  },
  computed: {
    comorbidityData() {
      if (this.$store.state.dashboardData == null) return null;
      var data = this.$store.state.dashboardData["hivStatus"];
      var chart = [
        [
          this.$t("widgets.hivStatusWidget.status"),
          this.$t("widgets.hivStatusWidget.count"),
          { role: "annotation" },
        ],
      ];

      data.forEach((x) => {
        chart.push([x.name, x.patientCount, x.patientCount + ""]);
      });

      return chart;
    },
    hivStatus() {
      return this.$t("widgets.hivStatusWidget.status");
    },
    numberOfPatients() {
      return this.$t("widgets.hivStatusWidget.numberOfPatients");
    },
    filters() {
      return this.$store.getters.filters;
    },
    filtersRegion() {
      return this.filters.region;
    },
    filtersAge() {
      return this.filters.ageGroup;
    },
    filtersGni() {
      return this.filters.gni;
    },
    filtersHemoTypes() {
      return this.filters.hemophiliaType;
    },
    filtersRegistrationYears() {
      return this.filters.dateOfRegistration;
    },
  },
  watch: {
    comorbidityData(val) {
      if (val != null) this.loading = false;
    },
  },
  // apollo: {
  //   comorbidityStats: {
  //     query() {
  //       return gql`
  //         query ComorbidityStats(
  //           $regions: [String]
  //           $minAge: Int
  //           $maxAge: Int
  //           $gni: [String]
  //           $hemoTypes: [String]
  //           $minRegistrationYear: Int
  //           $maxRegistrationYear: Int
  //         ) {
  //           hivStatusStats(
  //             regions: $regions
  //             ageGroup: { min: $minAge, max: $maxAge }
  //             gni: $gni
  //             hemophiliaTypes: $hemoTypes
  //             registrationYear: {
  //               min: $minRegistrationYear
  //               max: $maxRegistrationYear
  //             }
  //           ) {
  //             name
  //             patientCount
  //           }
  //         }
  //       `;
  //     },
  //     variables() {
  //       var filters = this.filters;
  //       var v = {
  //         regions: filters.region,
  //         minAge: this.filtersAge.min,
  //         maxAge: this.filtersAge.max,
  //         age: this.filtersAge,
  //         gni: filters.gni,
  //         hemoTypes: filters.hemophiliaType,
  //         minRegistrationYear: this.filtersRegistrationYears.min,
  //         maxRegistrationYear: this.filtersRegistrationYears.max,
  //       };
  //       return v;
  //     },
  //     update: function (data) {
  //       return data.hivStatusStats;
  //     },
  //     watchLoading({ loading, etc }) {
  //       this.loading = loading;
  //     },
  //   },
  // },
  created() {
    this.loading = true;
  },
  updated() {
    this.loading = false;
  },
};
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-widget',{attrs:{"loading":_vm.loading,"type":"graph","md":12,"sm":4}},[_c('template',{slot:"title"},[_vm._v(_vm._s(_vm.$t("widgets.bleedEventsByLocationWidget.title")))]),_c('template',{slot:"text"},[_c('v-switch',{attrs:{"color":"#0082ed"},model:{value:(_vm.chartSwitch),callback:function ($$v) {_vm.chartSwitch=$$v},expression:"chartSwitch"}}),_c('v-slide-y-transition',[(_vm.chartSwitch && !_vm.loading)?_c('GChart',{staticClass:"pa-15",attrs:{"setting":{ packages: ['corechart', 'bar'] },"type":"PieChart","data":_vm.bleedData,"options":{
          pieHole: 0.4,
          animation: {
            duration: 1500,
            easing: 'out',
            startup: true,
          },
          legend: {
            position: 'right',
            maxLines: '11',
          },
          chartArea: {
            width: '100%',
            height: '100%',
          },
          width: 'auto',
          height: '500',
          colors: ['#bfbfbf', '#808080', '#0082ed'],
          sliceVisibilityThreshold: 0,
        }}}):_vm._e()],1),_c('v-slide-y-transition',[(!_vm.chartSwitch && !_vm.loading)?_c('GChart',{attrs:{"setting":{ packages: ['corechart'] },"type":"ColumnChart","data":_vm.bleedData,"options":{
          animation: {
            duration: 1500,
            easing: 'out',
            startup: true,
          },
          legend: {
            position: 'none',
          },
          width: 'auto',
          height: '500',
          colors: ['#0082ed'],
          vAxis: {
            title: _vm.numberOfPatients,
            titleTextStyle: {
              bold: true,
              italic: false,
            },
            gridlines: {
              count: 10,
            },
          },
          hAxis: {
            title: _vm.bleedLocation,
            titleTextStyle: {
              bold: true,
              italic: false,
            },
          },
          annotations: {
            alwaysOutside: true,
            textStyle: {
              opacity: 1,
            },
            stem: {
              length: 0,
            },
          },
        }}}):_vm._e()],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <!-- NEW LAYOUT 25 OCTOBER 2021 -Mario -->
  <div class="md_center">
    <img src="/assets/img/adult.jpg" />
    <div class="flex-col">
      <p>Total number of people with hemophilia</p>
      <h3>{{ patientCount }}</h3>
    </div>
  </div>
</template>



<script>
// import BaseWidget from "./BaseWidget.vue";

export default {
  // components: { baseWidget: BaseWidget },
  data() {
    return {
      patientStats: 0,
      loading: true,
    };
  },
  computed: {
    patientCount() {
      if (this.$store.state.dashboardData == null) return 0;
      return this.$store.state.dashboardData["totalNumberOfPatients"];
    },
    filters() {
      return this.$store.getters.filters;
    },
    filtersRegion() {
      return this.filters.region;
    },
    filtersAge() {
      return this.filters.ageGroup;
    },
    filtersGni() {
      return this.filters.gni;
    },
    filtersRegistrationYears() {
      return this.filters.dateOfRegistration;
    },
  },
  watch: {
    patientCount(val) {
      if (val != null) this.loading = false;
    },
  },
  created() {
    this.loading = true;
  },
  updated() {
    this.loading = false;
  },
};
</script>


<style scoped>
.md_center img {
  max-height: 80px;
}
.md_center h3 {
  color: #666;
}
.md_center {
  display: flex;
  //justify-content: center;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
  padding-left: 1em;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-widget',{attrs:{"loading":_vm.loading,"type":"graph","md":12,"sm":4}},[_c('template',{slot:"title"},[_vm._v(_vm._s(_vm.$t("widgets.patientsWithProphylaxisRatioWidget.title")))]),_c('template',{slot:"text"},[_c('v-slide-y-transition',[(!_vm.loading)?_c('GChart',{staticClass:"pa-15",attrs:{"setting":{ packages: ['corechart', 'bar'] },"type":"PieChart","data":_vm.chartData,"options":{
          animation: {
            duration: 1500,
            easing: 'out',
            startup: true,
          },
          legend: {
            position: 'bottom',
            textStyle: {
              fontSize: ("" + _vm.legendFontSize),
            },
          },
          chartArea: {
            width: '100%',
            height: '80%',
          },
          width: '500',
          height: '280',
          colors: ['#8c1f70', '#d6d8d9', '#b91b66', '#123175'],
        }}}):_vm._e()],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
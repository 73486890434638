<template>
  <!-- NEW LAYOUT 25 OCTOBER 2021 -Mario -->
  <div class="md_center">
    <img src="/assets/img/country.jpg" />
    <div class="flex-col">
      <p class="word-break">{{ $t("widgets.countryCount.title") }}</p>
      <h3>{{ countryCount }}</h3>
    </div>
  </div>
</template>


<script>
// import BaseWidget from "./BaseWidget.vue";

export default {
  // components: { baseWidget: BaseWidget },
  data() {
    return {
      countryStats: [],
      loading: true,
      md: 12,
      sm: 4,
    };
  },
  computed: {
    countryCount() {
      if (this.$store.state.dashboardData == null) return 0;
      return this.$store.state.dashboardData["countryCount"];
    },
  },
  watch: {
    countryCount(val) {
      if (val != null) this.loading = false;
    },
  },
  created() {
    this.loading = true;
  },
  updated() {
    this.loading = false;
  },
  //   apollo: {
  //     countryStats: {
  //       query: gql`
  //         query NMOData($regions: [String], $gni: [String]) {
  //           nmoData(regions: $regions, gni: $gni) {
  //             countryCode
  //           }
  //         }
  //       `,
  //       variables() {
  //         return {
  //           regions: this.$store.state.filters.region,
  //           gni: this.$store.state.filters.gni,
  //         };
  //       },
  //       update: (data) => data.nmoData,
  //       watchLoading({ loading, key }) {
  //         this.loading = loading;
  //       },
  //     },
  //   },
};
</script>





<style scoped>
.word-break {
  word-break: break-word;
}

.md_center img {
  max-width: 120px;
}
.md_center h3 {
  color: #666;
}
.md_center {
  display: flex;
  //justify-content: center;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
  padding-left: 1em;
}
</style>
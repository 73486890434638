<template>
  <v-col class="pa-0 ma-0" style="background-color: #d9d9d9">
    <v-card
      class="d-flex flex-column pa-0 ma-0"
      style="background-color: #d9d9d9"
      height="100%"
      :md="md"
      :sm="sm"
      elevation="0"
      :titleUnder="true"
    >
      <patient-count-widget></patient-count-widget>
      <country-count-widget></country-count-widget>
      <htc-count-widget></htc-count-widget>
    </v-card>
  </v-col>
</template>
<script>
import PatientCounterWidget from "./PatientCounterWidget";
import CountryCountWidget from "./CountryCountWidget";
import HTCCountWidget from "./HTCCountWidget";
export default {
  components: {
    patientCountWidget: PatientCounterWidget,
    countryCountWidget: CountryCountWidget,
    htcCountWidget: HTCCountWidget,
  },
  data() {
    return {
      md: 3,
      sm: 12,
    };
  },
};
</script>
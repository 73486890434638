<template>
  <v-footer app padless inset absolute>
    <v-card flat tile width="100%">
      <v-card-text class="footer">
        <v-row
          ><v-col class="logo-text d-flex justify-center"
            ><b>Visionary Partners</b></v-col
          ></v-row
        >
        <v-row>
          <v-col class="d-flex flex-wrap justify-center">
            <v-img
              class="mx-2 mb-5"
              contain
              src="/assets/img/takeda_crop.jpg"
              min-width="100"
              max-width="100"
              max-height="50"
            ></v-img>
            <v-img
              class="mx-2 mb-5"
              contain
              src="/assets/img/sobi.jpg"
              min-width="100"
              max-width="100"
              max-height="50"
            ></v-img>
          </v-col>
        </v-row>

        <v-row
          ><v-col class="logo-text d-flex justify-center"
            ><b>Collaborating Partners</b></v-col
          ></v-row
        >
        <v-row>
          <v-col class="d-flex flex-wrap justify-center">
            <v-img
              class="mx-2 mb-5"
              contain
              src="/assets/img/bayer_logo.png"
              max-height="50"
              min-width="50"
              max-width="50"
            ></v-img>
            <v-img
              class="mx-2 mb-5"
              contain
              src="/assets/img/grifols_logo.jpg"
              max-height="50"
              min-width="100"
              max-width="100"
            ></v-img>
            <v-img
              class="mx-2 mb-5"
              contain
              src="/assets/img/novo_nordisk_crop.jpg"
              max-height="50"
              min-width="70"
              max-width="70"
            ></v-img>
            <v-img
              class="mx-2 mb-5"
              contain
              src="/assets/img/pfizer.png"
              max-height="50"
              min-width="100"
              max-width="100"
            ></v-img>
            <v-img
              class="mx-2 mb-5"
              contain
              src="/assets/img/roche_crop.png"
              max-height="50"
              min-width="75"
              max-width="75"
            ></v-img>
            <v-img
              class="mx-2 mb-5"
              contain
              src="/assets/img/sanfoni_crop.jpg"
              max-height="75"
              min-width="150"
              max-width="150"
            ></v-img>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col
            ><v-img src="/assets/img/WFH_logo.jpg" width="200"></v-img>
          </v-col>
          <v-col
            class="d-flex justify-space-around"
            v-for="(item, index) in images"
            :key="index"
          >
            <v-img
              :src="item.path"
              :max-width="item.width ? item.width : defaultWidth"
              contain
            ></v-img>
          </v-col>
        </v-row> -->
        <v-row>
          <v-spacer></v-spacer>
          <v-col justify="center" align="center">
            <!-- <div>
              <v-row>
                <v-col>
                  <v-img
                    contain
                    src="/assets/img/WFH_logo_crop.jpg"
                    width="200"
                    max-width="200"
                  ></v-img>
                </v-col>
              </v-row>
            </div> -->
            <span class="text-caption"
              >© World Federation of Hemophilia
              {{ new Date().getFullYear() }}</span
            >
            <a
              href="https://www.wfh.org/en/our-work-research-data/world-bleeding-disorders-registry"
              ><div class="text-caption">About WBDR</div></a
            >

            <a href="mailto:wbdr@wfh.org"
              ><div class="text-caption">wbdr@wfh.org</div></a
            >
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script>
export default {
  data: () => ({
    defaultWidth: 100,
    visionaryPartnerImages: [
      {
        path: "/assets/img/sobi.jpg",
      },
      {
        path: "/assets/img/takeda.jpg",
      },
    ],
    collaboratingPartnerImages: [
      {
        path: "/assets/img/bayer_logo.png",
        width: 50,
      },
      {
        path: "/assets/img/grifols_logo.jpg",
      },
      {
        path: "/assets/img/novo_nordisk.jpg",
      },
      {
        path: "/assets/img/pfizer.png",
      },
      {
        path: "/assets/img/roche.png",
      },
      {
        path: "/assets/img/sanfoni.jpg",
        width: 175,
      },
    ],
    // images: [
    //   {
    //     path: "/assets/img/CSL_Behring.webp",
    //   },
    // ],
  }),
};
</script>
<style scoped>
</style>
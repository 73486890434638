<template>
    <section>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            filename="Number of people with hemophilia by region"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="landscape"
            pdf-content-width="800px"
            @hasDownloaded="hasDownloaded($event)"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                <!-- PDF Content Here -->
                <base-widget :loading="loading" type="graph" :md="12" :sm="4">
                    <template slot="title">
                        {{
                            this.$t("widgets.patientCountByRegion.title")
                        }}</template
                    >

                    <template slot="text">
                        <v-slide-y-transition>
                            <GChart
                                v-if="!loading"
                                :setting="{ packages: ['corechart'] }"
                                type="ColumnChart"
                                :data="chartData"
                                :options="{
                                    animation: {
                                        duration: 1500,
                                        easing: 'out',
                                        startup: true,
                                    },
                                    legend: {
                                        position: 'none',
                                    },
                                    vAxis: {
                                        title: patientCount,
                                        titleTextStyle: {
                                            bold: true,
                                            italic: false,
                                        },
                                        gridlines: {
                                            count: 10,
                                        },
                                        viewWindow: {
                                            min: 0,
                                            max: highestValue,
                                        },
                                        viewWindowMode: 'explicit',
                                    },
                                    hAxis: {
                                        title: region,
                                        titleTextStyle: {
                                            bold: true,
                                            italic: false,
                                        },
                                    },
                                    annotations: {
                                        alwaysOutside: true,
                                        textStyle: {
                                            opacity: 1,
                                        },
                                        stem: {
                                            length: 0,
                                        },
                                    },
                                    width: 'auto',
                                    height: '500',
                                }"
                            ></GChart>
                        </v-slide-y-transition>
                    </template>
                </base-widget>
            </section>
        </vue-html2pdf>

        <!-- This repeated section is displayed on the screen -->

        <base-widget :loading="loading" type="graph" :md="12" :sm="4">
            <template slot="title">
                {{ this.$t("widgets.patientCountByRegion.title") }}</template
            >

            <template slot="text">
                <v-slide-y-transition>
                    <GChart
                        v-if="!loading"
                        :setting="{ packages: ['corechart'] }"
                        type="ColumnChart"
                        :data="chartData"
                        :options="{
                            animation: {
                                duration: 1500,
                                easing: 'out',
                                startup: true,
                            },
                            legend: {
                                position: 'none',
                            },
                            vAxis: {
                                title: patientCount,
                                titleTextStyle: {
                                    bold: true,
                                    italic: false,
                                },
                                gridlines: {
                                    count: 10,
                                },
                                viewWindow: {
                                    min: 0,
                                    max: highestValue,
                                },
                                viewWindowMode: 'explicit',
                            },
                            hAxis: {
                                title: region,
                                titleTextStyle: {
                                    bold: true,
                                    italic: false,
                                },
                            },
                            annotations: {
                                alwaysOutside: true,
                                textStyle: {
                                    opacity: 1,
                                },
                                stem: {
                                    length: 0,
                                },
                            },
                            width: 'auto',
                            height: '500',
                        }"
                    ></GChart>
                </v-slide-y-transition>
            </template>
        </base-widget>
        <div class="dl">
            <input
                type="image"
                src="assets/img/ikon.png"
                @click="generateReport"
            />
        </div>
    </section>
</template>
<script>
import BaseWidget from "./BaseWidget.vue";
import VueHtml2pdf from "vue-html2pdf";

export default {
    components: {
        baseWidget: BaseWidget,
        VueHtml2pdf,
    },
    data() {
        return {
            loading: true,
            patientStats: [],
            maxValue: 0,
        };
    },

    methods: {
        generateReport() {
            this.startPagination();
            this.$refs.html2Pdf.generatePdf();
        },
        startPagination() {
            this.$store.commit("toggleIsDashboardLoadingCustomText", {
                toggle: true,
                text: this.$t("app.generatingPdf"),
            });
        },
        hasDownloaded(event) {
            this.$store.commit("toggleIsDashboardLoading", false);
        },
    },

    computed: {
        highestValue() {
            let v = [];
            for (const x in this.$store.state.dashboardData
                .patientCountByRegion) {
                v.push(
                    this.$store.state.dashboardData.patientCountByRegion[x]
                        .patientCount
                );
            }
            return Math.max(...v) + 300;
        },

        chartData() {
            if (this.$store.state.dashboardData == null) return null;
            var data = this.$store.state.dashboardData["patientCountByRegion"];

            var chart = [
                [
                    this.$t("widgets.patientCountByRegion.regionName"),
                    this.$t("widgets.patientCountByRegion.patientCount"),
                    { role: "annotation" },
                    { role: "style" },
                ],
            ];

            if (data.length == 0) {
                chart.push(["", 0, "", ""]);
                return chart;
            }

            data.forEach((x, index) => {
                var region = x.regionName;

                // switch (x.regionName) {
                //   case "Africa":
                //     region = this.$t("filters.region.africa");
                //     break;
                //   case "LM":
                //     region = this.$t("filters.grossNationalIncome.middleLow");
                //     break;
                //   case "UM":
                //     region = this.$t("filters.grossNationalIncome.middleHigh");
                //     break;
                //   case "H":
                //     region = this.$t("filters.grossNationalIncome.high");
                //     break;
                // }

                chart.push([
                    region,
                    Math.round(x.patientCount),
                    Math.round(x.patientCount) < 1
                        ? "< 1"
                        : Math.round(x.patientCount) + "",
                    "#0067ab",
                ]);
                if (this.maxValue < Math.round(x.patientCount))
                    this.maxValue = Math.round(x.patientCount);
            });
            return chart;
        },
        region() {
            return this.$t("widgets.patientCountByRegion.region");
        },
        patientCount() {
            return this.$t("widgets.patientCountByRegion.patientCount");
        },
    },
    watch: {
        chartData(val) {
            if (val != null) this.loading = false;
        },
    },
};
</script>

<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="filter-header">{{
      this.$t("filters.severity.title")
    }}</v-expansion-panel-header>
    <v-expansion-panel-content class="pt-0">
      <v-checkbox
        color="#0082ed"
        :label="$t('filters.hemophiliaType.all')"
        v-model="severity"
        @change="severityChangeFilter('all')"
        value="all"
        hide-details
      ></v-checkbox>
      <v-checkbox
        class="my-0 py-0"
        v-for="severityTypeInstance in severityTypes"
        :key="severityTypeInstance.value"
        color="#0082ed"
        :label="severityTypeInstance.label"
        v-model="severity"
        @change="severityChangeFilter($event)"
        :value="severityTypeInstance.value"
        hide-details
      ></v-checkbox></v-expansion-panel-content
  ></v-expansion-panel>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      severity: ["all"],
      severityTypes: [
        { label: this.$t("filters.severity.types.severe"), value: "severe" },
        {
          label: this.$t("filters.severity.types.moderate"),
          value: "moderate",
        },
        { label: this.$t("filters.severity.types.mild"), value: "mild" },
      ],
    };
  },
  computed: {
    filters() {
      return this.$store.state.filters;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler(o) {
        if (o["severity"] == null) {
          this.severity = ["all"];
        }
      },
    },
  },
  methods: {
    applyFilters(types) {
      if (this.updateTimeout) clearTimeout(this.updateTimeout);
      this.updateTimeout = setTimeout(() => {
        var filters = this.$store.state.filters;
        filters.severity = types;
        this.$store.commit("setFilters", filters);
      }, 500);
    },
    severityChangeFilter(type) {
      if (
        type == "all" ||
        this.severity.length == 0 ||
        this.severity.length == this.severityTypes.length
      ) {
        this.severity = ["all"];
        this.applyFilters(null);
      } else {
        this.severity = this.severity.filter((x) => x != "all");
        this.applyFilters(this.severity);
      }
    },
  },
};
</script>
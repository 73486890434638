<template>
    <v-dialog v-model="dialog" persistent width="400" hide-overlay>
        <v-card color="#0082ed" dark>
            <v-card-text class="pt-3">
                {{ this.$store.getters.dashbordDataLoadingText }}
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    components: {},
    data() {
        return {};
    },
    computed: {
        dialog() {
            return this.$store.getters.isDashboardDataLoading;
        },
    },
    watch: {},
};
</script>

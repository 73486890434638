<template>
  <base-widget :loading="loading">
    <template slot="title">{{
      this.$t("widgets.patientsByDNAVariants.title")
    }}</template>
    <template slot="text">
      <v-switch v-model="chartSwitch" color="accent"></v-switch>
      <v-slide-y-transition>
        <GChart
          v-if="chartSwitch && !loading"
          :setting="{ package: ['corechart', 'bar'] }"
          type="PieChart"
          :data="patientsByDNAVariantsData"
          :options="{
            animation: {
              duration: 1500,
              easing: 'out',
              startup: true,
            },
            legend: {
              position: 'right',
              maxLines: '5',
            },
            width: 'auto',
            height: '500',
          }"
        ></GChart>
      </v-slide-y-transition>
      <v-slide-y-transition>
        <GChart
          v-if="!chartSwitch && !loading"
          :setting="{ package: ['corechart', 'bar'] }"
          type="ColumnChart"
          :data="patientsByDNAVariantsData"
          :options="{
            animation: {
              duration: 1500,
              easing: 'out',
              startup: true,
            },
            legend: {
              position: 'none',
            },
            width: 'auto',
            height: '500',
          }"
        ></GChart>
      </v-slide-y-transition>
    </template>
  </base-widget>
</template>
<script>
import BaseWidget from "./BaseWidget.vue";
import gql from "graphql-tag";
export default {
  components: { baseWidget: BaseWidget },
  data() {
    return {
      loading: false,
      patientsByDNAVariants: [],
      chartSwitch: true,
    };
  },
  computed: {
    patientsByDNAVariantsData() {
      var chart = [
        [
          this.$t("widgets.patientsByDNAVariants.variant"),
          this.$t("widgets.patientsByDNAVariants.count"),
          { role: "annotation" },
        ],
      ];

      this.patientsByDNAVariants.forEach((x) => {
        chart.push([x.dnaVariant, x.patientCount, x.patientCount + ""]);
      });

      return chart;
    },
  },
  apollo: {
    patientsByDNAVariants: {
      query: gql`
        query {
          patientsStats {
            patientsByDNAVariant {
              dnaVariant
              patientCount
            }
          }
        }
      `,
      update: function (data) {
        return data.patientsStats.patientsByDNAVariant;
      },
      watchLoading({ loading, key }) {
        this.loading = loading;
      },
    },
  },
  created() {
    this.loading = true;
  },
};
</script>
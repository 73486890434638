<template>
  <section>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="Employment status of people with hemophilia"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="800px"
      @hasDownloaded="hasDownloaded($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <!-- PDF Content Here -->
        <base-widget :loading="loading" type="graph" :md="12" :sm="4">
          <template slot="title">{{
            $t("widgets.patientEmployement.title")
          }}</template>
          <template slot="text">
            <v-switch v-model="chartSwitch" color="#5b1b6e"></v-switch>
            <v-slide-y-transition>
              <GChart
                class="pa-1"
                v-if="chartSwitch && !loading"
                :setting="{ packages: ['corechart', 'bar'] }"
                type="PieChart"
                :data="patientEmployementData"
                :options="{
                  pieHole: 0.4,
                  animation: {
                    duration: 1500,
                    easing: 'out',
                    startup: true,
                  },
                  chartArea: {
                    width: '100%',
                    height: '100%',
                  },
                  legend: {
                    position: 'right',
                    textStyle: {
                      fontSize: `${legendFontSize}`,
                    },
                  },
                  height: '300',
                  colors: [
                    '#8c1f70',
                    '#009fdb',
                    '#5b1b6e',
                    '#b91b66',
                    '#123175',
                  ],
                  sliceVisibilityThreshold: 0,
                }"
              ></GChart>
            </v-slide-y-transition>
            <v-slide-y-transition>
              <GChart
                v-if="!chartSwitch && !loading"
                :setting="{ packages: ['corechart'] }"
                type="ColumnChart"
                :data="patientEmployementData"
                :options="{
                  animation: {
                    duration: 1500,
                    easing: 'out',
                    startup: true,
                  },
                  legend: {
                    position: 'none',
                  },
                  hAxis: {
                    title: employementStatus,
                    titleTextStyle: {
                      bold: true,
                      italic: false,
                    },
                  },
                  vAxis: {
                    title: numberOfPatients,
                    titleTextStyle: {
                      bold: true,
                      italic: false,
                    },
                    gridlines: {
                      count: 10,
                    },
                    viewWindow: {
                      min: 0,
                      max: highestValue,
                    },
                  },
                  chartArea: {
                    top: '55',
                    height: '60%',
                  },
                  annotations: {
                    alwaysOutside: true,
                    textStyle: {
                      opacity: 1,
                    },
                    stem: {
                      length: 0,
                    },
                  },
                  width: 'auto',
                  height: '400',
                  colors: ['#0067ab'],
                }"
              ></GChart>
            </v-slide-y-transition>
          </template>
        </base-widget>
      </section>
    </vue-html2pdf>

    <base-widget :loading="loading" type="graph" :md="12" :sm="4">
      <template slot="title">{{
        $t("widgets.patientEmployement.title")
      }}</template>
      <template slot="text">
        <v-switch v-model="chartSwitch" color="#5b1b6e"></v-switch>
        <!-- <div class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-circular>
      </div> -->
        <v-slide-y-transition>
          <GChart
            class="pa-1"
            v-if="chartSwitch && !loading"
            :setting="{ packages: ['corechart', 'bar'] }"
            type="PieChart"
            :data="patientEmployementData"
            :options="{
              pieHole: 0.4,
              animation: {
                duration: 1500,
                easing: 'out',
                startup: true,
              },
              chartArea: {
                width: '100%',
                height: '100%',
              },
              legend: {
                position: 'right',
                textStyle: {
                  fontSize: `${legendFontSize}`,
                },
              },
              height: '300',
              colors: ['#8c1f70', '#009fdb', '#5b1b6e', '#b91b66', '#123175'],
              sliceVisibilityThreshold: 0,
            }"
          ></GChart>
        </v-slide-y-transition>
        <v-slide-y-transition>
          <GChart
            v-if="!chartSwitch && !loading"
            :setting="{ packages: ['corechart'] }"
            type="ColumnChart"
            :data="patientEmployementData"
            :options="{
              animation: {
                duration: 1500,
                easing: 'out',
                startup: true,
              },
              legend: {
                position: 'none',
              },
              hAxis: {
                title: employementStatus,
                titleTextStyle: {
                  bold: true,
                  italic: false,
                },
              },
              vAxis: {
                title: numberOfPatients,
                titleTextStyle: {
                  bold: true,
                  italic: false,
                },
                gridlines: {
                  count: 10,
                },
                viewWindow: {
                  min: 0,
                  max: highestValue,
                },
              },
              chartArea: {
                top: '55',
                height: '60%',
              },
              annotations: {
                alwaysOutside: true,
                textStyle: {
                  opacity: 1,
                },
                stem: {
                  length: 0,
                },
              },
              width: 'auto',
              height: '400',
              colors: ['#0067ab'],
            }"
          ></GChart>
        </v-slide-y-transition>
      </template>
    </base-widget>
    <div class="dl">
      <input type="image" src="assets/img/ikon.png" @click="generateReport" />
    </div>
  </section>
</template>
<script>
import BaseWidget from "./BaseWidget.vue";
import gql from "graphql-tag";
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: { baseWidget: BaseWidget, VueHtml2pdf },
  data() {
    return {
      loading: true,
      patientEmployement: [],
      chartSwitch: true,
    };
  },
  methods: {
    generateReport() {
      this.startPagination();
      this.$refs.html2Pdf.generatePdf();
    },
    startPagination() {
      this.$store.commit("toggleIsDashboardLoadingCustomText", {
        toggle: true,
        text: this.$t("app.generatingPdf"),
      });
    },
    hasDownloaded(event) {
      this.$store.commit("toggleIsDashboardLoading", false);
    },
  },
  computed: {
    highestValue() {
      let v = [];
      for (const x in this.$store.state.dashboardData.employmentStatuses) {
        v.push(
          this.$store.state.dashboardData.employmentStatuses[x].patientCount
        );
      }
      return Math.max(...v) + 250;
    },
    colorScheme() {
      return this.$store.state.colorScheme;
    },
    patientEmployementData() {
      if (this.$store.state.dashboardData == null) return null;
      var data = this.$store.state.dashboardData["employmentStatuses"];

      var chart = [
        [
          this.$t("widgets.patientEmployement.employement"),
          this.$t("widgets.patientEmployement.count"),
          { role: "annotation" },
          { role: "style" },
        ],
      ];

      if (data.length == 0) {
        chart.push(["", 0, "", ""]);
        return chart;
      }
      //
      data.forEach((x, i) => {
        if (i == 1 || i == 3) {
          chart.push([
            x.employmentStatus,
            x.patientCount,
            x.patientCount + "",
            "#888888",
          ]);
        } else {
          chart.push([
            x.employmentStatus,
            x.patientCount,
            x.patientCount + "",
            "#0067ab",
          ]);
        }
      });

      return chart;
    },
    numberOfPatients() {
      return this.$t("widgets.patientEmployement.numberOfPatients");
    },
    employementStatus() {
      return this.$t("widgets.patientEmployement.employementStatus");
    },
    filters() {
      return this.$store.getters.filters;
    },
    filtersRegion() {
      return this.filters.region;
    },
    filtersAge() {
      return this.filters.ageGroup;
    },
    filtersGni() {
      return this.filters.gni;
    },
    filtersHemoTypes() {
      return this.filters.hemophiliaType;
    },
    filtersRegistrationYears() {
      return this.filters.dateOfRegistration;
    },
    legendFontSize() {
      if (this.$vuetify.breakpoint.name == "xl") return 16;
      return 12;
    },
  },
  watch: {
    patientEmployementData(val) {
      if (val != null) this.loading = false;
    },
  },
  // apollo: {
  //   patientEmployement: {
  //     query() {
  //       return gql`
  //         query PatientEmployement(
  //           $regions: [String]
  //           $minAge: Int
  //           $maxAge: Int
  //           $gni: [String]
  //           $hemoTypes: [String]
  //           $minRegistrationYear: Int
  //           $maxRegistrationYear: Int
  //         ) {
  //           latestPatientsEmployment(
  //             regions: $regions
  //             ageGroup: { min: $minAge, max: $maxAge }
  //             gni: $gni
  //             hemophiliaTypes: $hemoTypes
  //             registrationYear: {
  //               min: $minRegistrationYear
  //               max: $maxRegistrationYear
  //             }
  //           ) {
  //             patientCount
  //             employementStatus
  //           }
  //         }
  //       `;
  //     },
  //     variables() {
  //       var filters = this.filters;
  //       var v = {
  //         regions: filters.region,
  //         minAge: this.filtersAge.min,
  //         maxAge: this.filtersAge.max,
  //         age: this.filtersAge,
  //         gni: filters.gni,
  //         hemoTypes: filters.hemophiliaType,
  //         minRegistrationYear: this.filtersRegistrationYears.min,
  //         maxRegistrationYear: this.filtersRegistrationYears.max,
  //       };
  //       return v;
  //     },
  //     update: function (data) {
  //       return data.latestPatientsEmployment;
  //     },
  //     watchLoading({ loading, etc }) {
  //       this.loading = loading;
  //     },
  //   },
  // },
  created() {
    this.loading = true;
  },
  updated() {
    this.loading = false;
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"dl"},[_c('input',{attrs:{"type":"image","src":"assets/img/ikon.png"},on:{"click":_vm.generateReport}})]),_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"float-layout":true,"enable-download":true,"preview-modal":true,"paginate-elements-by-height":1400,"filename":"Patient count by hospitalization reason","pdf-quality":2,"manual-pagination":false,"pdf-format":"a4","pdf-orientation":"landscape","pdf-content-width":"800px"},on:{"hasDownloaded":function($event){return _vm.hasDownloaded($event)}}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('base-widget',{attrs:{"loading":_vm.loading,"type":"graph","md":12,"sm":4}},[_c('template',{slot:"title"},[_vm._v(_vm._s(_vm.$t("widgets.numberOfHospitalizationByReason.title")))]),_c('template',{slot:"text"},[_c('v-switch',{attrs:{"color":"accent"},model:{value:(_vm.chartSwitch),callback:function ($$v) {_vm.chartSwitch=$$v},expression:"chartSwitch"}}),_c('v-slide-y-transition',[(_vm.chartSwitch && !_vm.loading)?_c('GChart',{staticClass:"pa-15",attrs:{"setting":{ packages: ['corechart', 'bar'] },"type":"PieChart","data":_vm.chartData,"options":{
                animation: {
                  duration: 1500,
                  easing: 'out',
                  startup: true,
                },
                legend: {
                  position: 'right',
                },
                chartArea: {
                  width: '100%',
                  height: '100%',
                },
                width: 'auto',
                height: '500',
                colors: [
                  '#8c1f70',
                  '#009fdb',
                  '#5b1b6e',
                  '#b91b66',
                  '#123175',
                  '#009fdb' ],
                sliceVisibilityThreshold: 0,
              }}}):_vm._e()],1),_c('v-slide-y-transition',[(!_vm.chartSwitch && !_vm.loading)?_c('GChart',{attrs:{"setting":{ packages: ['corechart'] },"type":"ColumnChart","data":_vm.chartData,"options":{
                animation: {
                  duration: 1500,
                  easing: 'out',
                  startup: true,
                },
                legend: {
                  position: 'none',
                },
                hAxis: {
                  title: _vm.hospitalizationReason,
                  titleTextStyle: {
                    bold: true,
                    italic: false,
                  },
                },
                vAxis: {
                  title: _vm.patientsCount,
                  titleTextStyle: {
                    bold: true,
                    italic: false,
                  },
                },
                annotations: {
                  alwaysOutside: true,
                },
                width: 'auto',
                height: '500',
                colors: ['#0067ab'],
              }}}):_vm._e()],1)],1)],2)],1)]),_c('base-widget',{attrs:{"loading":_vm.loading,"type":"graph","md":12,"sm":4}},[_c('template',{slot:"title"},[_vm._v(_vm._s(_vm.$t("widgets.numberOfHospitalizationByReason.title")))]),_c('template',{slot:"text"},[_c('v-switch',{attrs:{"color":"accent"},model:{value:(_vm.chartSwitch),callback:function ($$v) {_vm.chartSwitch=$$v},expression:"chartSwitch"}}),_c('v-slide-y-transition',[(_vm.chartSwitch && !_vm.loading)?_c('GChart',{staticClass:"pa-15",attrs:{"setting":{ packages: ['corechart', 'bar'] },"type":"PieChart","data":_vm.chartData,"options":{
            animation: {
              duration: 1500,
              easing: 'out',
              startup: true,
            },
            legend: {
              position: 'right',
            },
            chartArea: {
              width: '100%',
              height: '100%',
            },
            width: 'auto',
            height: '500',
            colors: [
              '#8c1f70',
              '#009fdb',
              '#5b1b6e',
              '#b91b66',
              '#123175',
              '#009fdb' ],
            sliceVisibilityThreshold: 0,
          }}}):_vm._e()],1),_c('v-slide-y-transition',[(!_vm.chartSwitch && !_vm.loading)?_c('GChart',{attrs:{"setting":{ packages: ['corechart'] },"type":"ColumnChart","data":_vm.chartData,"options":{
            animation: {
              duration: 1500,
              easing: 'out',
              startup: true,
            },
            legend: {
              position: 'none',
            },
            hAxis: {
              title: _vm.hospitalizationReason,
              titleTextStyle: {
                bold: true,
                italic: false,
              },
            },
            vAxis: {
              title: _vm.patientsCount,
              titleTextStyle: {
                bold: true,
                italic: false,
              },
            },
            annotations: {
              alwaysOutside: true,
            },
            width: 'auto',
            height: '500',
            colors: ['#0067ab'],
          }}}):_vm._e()],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
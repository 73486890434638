<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="filter-header">{{
      this.$t("filters.hemophiliaType.title")
    }}</v-expansion-panel-header>
    <v-expansion-panel-content class="pt-0">
      <v-checkbox
        color="#0082ed"
        :label="$t('filters.hemophiliaType.all')"
        v-model="hemophiliaType"
        @change="hemophiliaTypeFilter('all')"
        value="all"
        hide-details
      ></v-checkbox>
      <v-checkbox
        class="my-0 py-0"
        v-for="hemophiliaTypeInstance in hemophiliaTypeList"
        :key="hemophiliaTypeInstance.value"
        color="#0082ed"
        :label="hemophiliaTypeInstance.label"
        v-model="hemophiliaType"
        @change="hemophiliaTypeFilter($event)"
        :value="hemophiliaTypeInstance.value"
        hide-details
      ></v-checkbox>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
export default {
  data() {
    return {
      updateTimeout: null,
      hemophiliaType: ["all"],
      hemophiliaTypeList: [
        { label: this.$t("filters.hemophiliaType.typeA"), value: "A" },
        { label: this.$t("filters.hemophiliaType.typeB"), value: "B" },
      ],
    };
  },
  computed: {
    filters() {
      return this.$store.state.filters;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler(o) {
        if (o["hemophiliaType"] == null) {
          this.hemophiliaType = ["all"];
        }
      },
    },
  },
  methods: {
    applyFilters(types) {
      if (this.updateTimeout) clearTimeout(this.updateTimeout);
      this.updateTimeout = setTimeout(() => {
        var filters = this.$store.state.filters;
        filters.hemophiliaType = types;
        this.$store.commit("setFilters", filters);
      }, 500);
    },
    hemophiliaTypeFilter(type) {
      if (
        type == "all" ||
        this.hemophiliaType.length == 0 ||
        this.hemophiliaType.length == 3
      ) {
        this.hemophiliaType = ["all"];
        this.applyFilters(null);
      } else {
        this.hemophiliaType = this.hemophiliaType.filter((x) => x != "all");
        this.applyFilters(this.hemophiliaType);
      }
    },
  },
};
</script>
<template>
  <section>
    <div class="dl">
      <input type="image" src="assets/img/ikon.png" @click="generateReport" />
    </div>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="Patient count by hospitalization reason"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="800px"
      @hasDownloaded="hasDownloaded($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <!-- PDF Content Here -->

        <base-widget :loading="loading" type="graph" :md="12" :sm="4">
          <template slot="title">{{
            $t("widgets.numberOfHospitalizationByReason.title")
          }}</template>
          <template slot="text">
            <v-switch v-model="chartSwitch" color="accent"></v-switch>
            <v-slide-y-transition>
              <GChart
                class="pa-15"
                v-if="chartSwitch && !loading"
                :setting="{ packages: ['corechart', 'bar'] }"
                type="PieChart"
                :data="chartData"
                :options="{
                  animation: {
                    duration: 1500,
                    easing: 'out',
                    startup: true,
                  },
                  legend: {
                    position: 'right',
                  },
                  chartArea: {
                    width: '100%',
                    height: '100%',
                  },
                  width: 'auto',
                  height: '500',
                  colors: [
                    '#8c1f70',
                    '#009fdb',
                    '#5b1b6e',
                    '#b91b66',
                    '#123175',
                    '#009fdb',
                  ],
                  sliceVisibilityThreshold: 0,
                }"
              ></GChart>
            </v-slide-y-transition>
            <v-slide-y-transition>
              <GChart
                v-if="!chartSwitch && !loading"
                :setting="{ packages: ['corechart'] }"
                type="ColumnChart"
                :data="chartData"
                :options="{
                  animation: {
                    duration: 1500,
                    easing: 'out',
                    startup: true,
                  },
                  legend: {
                    position: 'none',
                  },
                  hAxis: {
                    title: hospitalizationReason,
                    titleTextStyle: {
                      bold: true,
                      italic: false,
                    },
                  },
                  vAxis: {
                    title: patientsCount,
                    titleTextStyle: {
                      bold: true,
                      italic: false,
                    },
                  },
                  annotations: {
                    alwaysOutside: true,
                  },
                  width: 'auto',
                  height: '500',
                  colors: ['#0067ab'],
                }"
              ></GChart>
            </v-slide-y-transition>
          </template>
        </base-widget>
      </section>
    </vue-html2pdf>

    <base-widget :loading="loading" type="graph" :md="12" :sm="4">
      <template slot="title">{{
        $t("widgets.numberOfHospitalizationByReason.title")
      }}</template>
      <template slot="text">
        <v-switch v-model="chartSwitch" color="accent"></v-switch>
        <v-slide-y-transition>
          <GChart
            class="pa-15"
            v-if="chartSwitch && !loading"
            :setting="{ packages: ['corechart', 'bar'] }"
            type="PieChart"
            :data="chartData"
            :options="{
              animation: {
                duration: 1500,
                easing: 'out',
                startup: true,
              },
              legend: {
                position: 'right',
              },
              chartArea: {
                width: '100%',
                height: '100%',
              },
              width: 'auto',
              height: '500',
              colors: [
                '#8c1f70',
                '#009fdb',
                '#5b1b6e',
                '#b91b66',
                '#123175',
                '#009fdb',
              ],
              sliceVisibilityThreshold: 0,
            }"
          ></GChart>
        </v-slide-y-transition>
        <v-slide-y-transition>
          <GChart
            v-if="!chartSwitch && !loading"
            :setting="{ packages: ['corechart'] }"
            type="ColumnChart"
            :data="chartData"
            :options="{
              animation: {
                duration: 1500,
                easing: 'out',
                startup: true,
              },
              legend: {
                position: 'none',
              },
              hAxis: {
                title: hospitalizationReason,
                titleTextStyle: {
                  bold: true,
                  italic: false,
                },
              },
              vAxis: {
                title: patientsCount,
                titleTextStyle: {
                  bold: true,
                  italic: false,
                },
              },
              annotations: {
                alwaysOutside: true,
              },
              width: 'auto',
              height: '500',
              colors: ['#0067ab'],
            }"
          ></GChart>
        </v-slide-y-transition>
      </template>
    </base-widget>
  </section>
</template>
<script>
import BaseWidget from "./BaseWidget";
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: { baseWidget: BaseWidget, VueHtml2pdf },
  data() {
    return {
      chartSwitch: true,
      loading: true,
    };
  },
  methods: {
    generateReport() {
      this.startPagination();
      this.$refs.html2Pdf.generatePdf();
    },
    startPagination() {
      this.$store.commit("toggleIsDashboardLoadingCustomText", {
        toggle: true,
        text: this.$t("app.generatingPdf"),
      });
    },
    hasDownloaded(event) {
      this.$store.commit("toggleIsDashboardLoading", false);
    },
  },
  computed: {
    filters() {
      return this.$store.getters.filters;
    },
    filtersRegion() {
      return this.filters.region;
    },
    filtersAge() {
      return this.filters.ageGroup;
    },
    filtersGni() {
      return this.filters.gni;
    },
    filtersHemoTypes() {
      return this.filters.hemophiliaType;
    },
    filtersRegistrationYears() {
      return this.filters.dateOfRegistration;
    },
    hospitalizationReason() {
      return this.$t("widgets.numberOfHospitalizationByReason.reason");
    },
    patientsCount() {
      return this.$t("widgets.numberOfHospitalizationByReason.count");
    },
    chartData() {
      if (this.$store.state.dashboardData == null) return null;
      var data =
        this.$store.state.dashboardData["numberOfHopitalizationByReasons"];
      var chart = [
        [
          this.$t("widgets.numberOfHospitalizationByReason.reason"),
          this.$t("widgets.numberOfHospitalizationByReason.count"),
          { role: "annotation" },
        ],
      ];

      if (data == null || data.length == 0) {
        chart.push(["", 0, ""]);
        return chart;
      }

      if (data != null) {
        data.forEach((x) => {
          chart.push([
            x.hospitalizationReason,
            x.patientCount,
            x.patientCount + "",
          ]);
        });
      }
      return chart;
    },
  },
  watch: {
    chartData(val) {
      if (val != null) this.loading = false;
    },
  },
  created() {
    this.loading = true;
  },
  updated() {
    this.loading = false;
  },
};
</script>
